import PrivateRoute from 'components/Common/PrivateRoute';
import Borders from 'components/Layouts/Borders';
import { trackPageView } from 'instrumentation/analytics';
import AuthRouter from 'pages/Authentication/Router';
import ConnectionRouter from 'pages/Connections/Router';
import PageNotFound from 'pages/Exceptions/PageNotFound';
import HomePage from 'pages/HomePage/HomePage';
import SubmissionsPage from 'pages/Submissions/SubmissionsPage';
import React from 'react';
import {
  Navigate, Route, Routes, useLocation,
} from 'react-router-dom';
import * as spaUrls from 'utils/spaUrls';
import Coaching from 'pages/Coaching/Coaching';
import ApplicationDetails from 'pages/HomePage/ApplicationDetails';
import QuestionsList from 'pages/Questions/QuestionsList';
import QuestionDetails from 'pages/Questions/QuestionDetails';


export default function Router() {
  const location = useLocation();

  React.useEffect(() => { trackPageView(); }, [location]);

  return (
    <Borders>
      <Routes>
        <Route path='/auth/*' element={<AuthRouter />} />
        <Route path='/connections/*' element={<ConnectionRouter />} />
        <Route path='/' element={<Navigate to={spaUrls.auth.login} replace />} />
        <Route
          path={spaUrls.coaching}
          element={<PrivateRoute outlet={<Coaching />} />}
        />
        <Route
          path={spaUrls.homepage}
          element={<PrivateRoute outlet={<HomePage />} />}
        />
        <Route
          path={spaUrls.applications.details}
          element={<PrivateRoute outlet={<ApplicationDetails />} />}
        />
        <Route
          path={spaUrls.questions.list}
          element={<PrivateRoute outlet={<QuestionsList />} />}
        />
        <Route
          path={spaUrls.questions.details}
          element={<PrivateRoute outlet={<QuestionDetails />} />}
        />
        <Route
          path={spaUrls.submissions}
          element={<PrivateRoute outlet={<SubmissionsPage />} />}
        />
        <Route path='/404' element={<PageNotFound />} />
        <Route path='*' element={<Navigate to='/404' replace />} />
      </Routes>
    </Borders>
  );
}
