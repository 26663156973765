import * as React from 'react';
import type { Components } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Theme } from '@mui/material';


function IconComponent(): React.JSX.Element {
  return (
    <KeyboardArrowDownIcon
      fontSize='medium'
      style={{
        flex: '0 0 auto', pointerEvents: 'none', position: 'absolute', right: '7px', top: 'calc(50% - .5em)',
      }}
    />
  );
}

const MuiSelect = {
  defaultProps: { displayEmpty: true, IconComponent, MenuProps: { sx: { mt: '4px' } } },
  styleOverrides: { select: { height: 'auto', minHeight: 'auto' } },
} satisfies Components<Theme>['MuiSelect'];

export default MuiSelect;
