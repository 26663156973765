import React from 'react';
import {
  Stack, Tooltip, Chip,
} from '@mui/material';
import { trimText } from 'pages/HomePage/components/utils';


type TProps = {
    skills: string[],
    renderNumber: number,
}

const colorOrder = ['#FF950026', '#007AFF26', '#AF52DE26'];

export default function CustomChip({ skills, renderNumber }: TProps) {
  const remainingNumber = skills.length - renderNumber;

  if (skills.length === 0) {
    return (
      <Stack direction='row' spacing={1} alignItems='center'>
        <Tooltip title='N/A'>
          <Chip
            label='N/A'
            variant='filled'
            color='default'
          />
        </Tooltip>
      </Stack>
    );
  }

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      {skills.slice(0, renderNumber).map((skill, index) => (
        <Tooltip title={skill}>
          <Chip
            key={skill}
            label={trimText(skill, 10)}
            variant='filled'
            sx={{ opacity: '60%', backgroundColor: colorOrder[index % colorOrder.length], color: 'black' }}
          />
        </Tooltip>
      ))}
      {remainingNumber > 0 && (
        <Tooltip title={skills.slice(renderNumber, skills.length).join(', ')}>
          <Chip
            color='default'
            label={`+${remainingNumber}`}
            variant='filled'
          />
        </Tooltip>
      )}
    </Stack>
  );
}
