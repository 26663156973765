import type { Components } from '@mui/material/styles';
import { Theme } from '@mui/material';
import MuiButton from 'styles/theme/components/Button';
import MuiCard from 'styles/theme/components/card';
import MuiCardContent from 'styles/theme/components/card-content';
import MuiCardHeader from 'styles/theme/components/card-header';
import MuiCardActions from 'styles/theme/components/card-actions';
import MuiStack from 'styles/theme/components/Stack';
import MuiListItemText from 'styles/theme/components/list-item-text';
import MuiList from 'styles/theme/components/list';
import MuiListItem from 'styles/theme/components/list-item';
import MuiListItemAvatar from 'styles/theme/components/list-item-avatar';
import MuiListItemButton from 'styles/theme/components/list-item-button';
import MuiListItemIcon from 'styles/theme/components/list-item-icon';
import MuiChip from 'styles/theme/components/chip';
import MuiTooltip from 'styles/theme/components/tooltip';
import MuiSelect from 'styles/theme/components/select';
import MuiTab from 'styles/theme/components/tab';


const components = {
  MuiButton,
  MuiCard,
  MuiCardHeader,
  MuiCardActions,
  MuiCardContent,
  MuiStack,
  MuiList,
  MuiListItem,
  MuiListItemAvatar,
  MuiListItemButton,
  MuiListItemIcon,
  MuiListItemText,
  MuiChip,
  MuiTooltip,
  MuiSelect,
  MuiTab,
} satisfies Components<Theme>;

export default components;
