import React from 'react';
import { Button } from '@mui/material';
import { EEvents } from 'instrumentation/analytics.types';
import { trackEvents } from 'instrumentation/analytics';


type TProps = {
    links: (string | null)[];
}

export default function ContactUsButton({ links }: TProps) {
  const supportEmail = 'support@codei.ai';
  const subject = encodeURIComponent('Error 403: URL could not be parsed');
  const body = encodeURIComponent(`I am a user at Codei and recently tried to track application. While doing so, I ran into a URL could not be parsed error for the following urls:\n${links.map((link) => `- ${link}`).join('\n')}`);

  const handleContactUs = () => {
    const mailtoLink = `mailto:${supportEmail}?subject=${subject}&body=${body}`;
    window.open(mailtoLink, '_blank');
  };

  return (
    <Button
      variant='contained'
      color='error'
      onClick={() => {
        handleContactUs();
        trackEvents(EEvents.CLICK, {
          feature: 'Common Component - Contact Us Button',
          type: 'Button',
          label: 'Contact Us',
        });
      }}
    >
      Contact Us
    </Button>
  );
}
