import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import UserSettingsPopover from 'components/Layouts/UserSettingsPopover';
import React, { useContext } from 'react';
import Divider from '@mui/material/Divider';
import { useMediaQuery, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { SideNavCtx } from 'components/Layouts/Borders';


export default function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setMobileOpen, mobileOpen } = useContext(SideNavCtx);

  const handleDrawerToggle = () => {
    if (setMobileOpen) setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar
      sx={{
        maxHeight: theme.custom.appBarHeight,
        backgroundColor: '#FFFFFF',
        zIndex: isMobile ? 1200 : 1201,
      }}
      elevation={0}
      position='fixed'
    >
      <Toolbar disableGutters>
        <Grid container justifyContent={isMobile ? 'space-between' : 'flex-end'} alignItems='center'>
          {isMobile && (
          <Grid item>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ ml: 1 }}
            >
              <MenuIcon color='primary' />
            </IconButton>
          </Grid>

          )}
          <Grid item xs={1} sx={{ textAlign: 'center', paddingRight: isMobile ? '15%' : '0' }}>
            <UserSettingsPopover />
          </Grid>
        </Grid>
      </Toolbar>
      <Grid item xs={12} sx={{ marginTop: isMobile ? '0px' : '-9px' }}>
        <Divider />
      </Grid>
    </AppBar>
  );
}
