import React, { useState, useEffect, useContext } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AppAlertsCtx } from 'components/Common/AppAlerts';
import { Logger } from 'utils/logger';


export default function CopyableTextField({ initialText }: { initialText: string[] }) {
  const { addAlert } = useContext(AppAlertsCtx);
  const [text, setText] = useState<string>('');

  useEffect(() => {
    setText(initialText.join('\n'));
  }, [initialText]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        addAlert({
          type: 'success',
          message: 'Text copied to clipboard successfully!',
        });
      })
      .catch((err) => {
        Logger.error('Could not copy text', err);
      });
  };

  return (
    <TextField
      label='Project Resume Points'
      variant='outlined'
      value={text}
      onChange={(e) => setText(e.target.value)}
      multiline // Allow multiline input
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={handleCopy} edge='end'>
              <ContentCopyIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
