// eslint-disable-next-line max-classes-per-file
import { EExperience, EPosition } from 'pages/HomePage/types';
import { IStatus } from 'pages/HomePage/queries';


class PositionParser {
  public readonly FULL_STACK: IStatus<EPosition> = {
    key: EPosition.POSITION_FULL_STACK,
    title: 'Full Stack',
    color: 'orange',
  };

  public readonly FRONTEND: IStatus<EPosition> = {
    key: EPosition.POSITION_FRONTEND,
    title: 'Frontend',
    color: 'green',
  };

  public readonly BACKEND: IStatus<EPosition> = {
    key: EPosition.POSITION_BACKEND,
    title: 'Backend',
    color: 'red',
  };

  public readonly DEVOPS: IStatus<EPosition> = {
    key: EPosition.POSITION_DEVOPS,
    title: 'DevOps',
    color: 'yellow',
  };

  public readonly OTHER: IStatus<EPosition> = {
    key: EPosition.POSITION_OTHER,
    title: 'Other',
    color: 'black',
  };

  public readonly SOFTWARE_ENGINEER: IStatus<EPosition> = {
    key: EPosition.POSITION_SOFTWARE_ENGINEER,
    title: 'Software Engineer',
    color: 'blue',
  };

  public readonly DATA_SCIENCE: IStatus<EPosition> = {
    key: EPosition.POSITION_DATA_SCIENCE,
    title: 'Data Science',
    color: 'yellow',
  };

  public readonly DEFAULT: IStatus<EPosition> = {
    key: EPosition.POSITION_DEFAULT,
    title: 'None',
    color: 'white',
  };

  public readonly parse = (status: EPosition): IStatus<EPosition> => {
    switch (status) {
      case EPosition.POSITION_FULL_STACK:
        return this.FULL_STACK;
      case EPosition.POSITION_FRONTEND:
        return this.FRONTEND;
      case EPosition.POSITION_BACKEND:
        return this.BACKEND;
      case EPosition.POSITION_DEVOPS:
        return this.DEVOPS;
      case EPosition.POSITION_OTHER:
        return this.OTHER;
      case EPosition.POSITION_SOFTWARE_ENGINEER:
        return this.SOFTWARE_ENGINEER;
      case EPosition.POSITION_DATA_SCIENCE:
        return this.DATA_SCIENCE;
      default:
        return this.DEFAULT;
    }
  };

  public readonly parseFromTitle = (title: string): EPosition => {
    const lowerCaseTitle = title.toLowerCase();
    if (lowerCaseTitle.includes('full stack')) return this.FULL_STACK.key;
    if (lowerCaseTitle.includes('frontend')) return this.FRONTEND.key;
    if (lowerCaseTitle.includes('backend')) return this.BACKEND.key;
    if (lowerCaseTitle.includes('devops')) return this.DEVOPS.key;
    if (lowerCaseTitle.includes('software engineer')) return this.SOFTWARE_ENGINEER.key;
    if (lowerCaseTitle.includes('data science')) return this.DATA_SCIENCE.key;
    return EPosition.POSITION_OTHER;
  };
}

export const positionParsed = new PositionParser();

class ExperienceParser {
  public readonly INTERN: IStatus<EExperience> = {
    key: EExperience.EXPERIENCE_INTERN,
    title: 'Intern',
    color: 'orange',
  };

  public readonly ENTRY: IStatus<EExperience> = {
    key: EExperience.EXPERIENCE_ENTRY,
    title: 'Entry',
    color: 'green',
  };

  public readonly JUNIOR: IStatus<EExperience> = {
    key: EExperience.EXPERIENCE_JUNIOR,
    title: 'Junior',
    color: 'red',
  };

  public readonly STAFF: IStatus<EExperience> = {
    key: EExperience.EXPERIENCE_STAFF,
    title: 'Staff',
    color: 'yellow',
  };

  public readonly OTHER: IStatus<EExperience> = {
    key: EExperience.EXPERIENCE_OTHER,
    title: 'Other',
    color: 'black',
  };

  public readonly SENIOR: IStatus<EExperience> = {
    key: EExperience.EXPERIENCE_SENIOR,
    title: 'Senior',
    color: 'blue',
  };

  public readonly parse = (status: EExperience): IStatus<EExperience> => {
    switch (status) {
      case EExperience.EXPERIENCE_SENIOR:
        return this.SENIOR;
      case EExperience.EXPERIENCE_OTHER:
        return this.OTHER;
      case EExperience.EXPERIENCE_INTERN:
        return this.INTERN;
      case EExperience.EXPERIENCE_ENTRY:
        return this.ENTRY;
      case EExperience.EXPERIENCE_JUNIOR:
        return this.JUNIOR;
      case EExperience.EXPERIENCE_STAFF:
        return this.STAFF;
      default:
        return this.OTHER;
    }
  };
}

export const experienceParsed = new ExperienceParser();


export const nonCrawlableDomains = [
  'https://www.linkedin.com/',
  'https://www.indeed.com/',
  'https://www.ziprecruiter.com/',
  'https://www.monster.com/',
  'https://www.simplyhired.com/',
  'https://joinhandshake.com/',
  'https://app.joinhandshake.com/',
  'https://www.glassdoor.com/',
];

export const quickGuideSteps = [
  [
    'Go to the job posting page',
    'Copy the URL from the browser',
    'Paste the URL in the first input box',
  ],
  [
    'Click the Codei extension icon',
    'Go to the job description page',
    'Click "Save Application" in the extension',
  ],
  [
    'Go to the job description page',
    'Copy the job description text',
    'Paste the text in the second input box',
  ],
];

export const quickGuideNote = [
  'Note: Only 80% of Job Descriptions can be parsed using URL.',
  'Note: Specially caters to links that cannot be crawled. Such links includes LinkedIn, Indeed, ZipRecruiter etc.',
  'Note: Success rate depends on user input. Job description should have company name, role, responsibilities.',
];

export const quickGuideVideoLinks = [
  'https://www.youtube.com/embed/v2QI_43U1qg?si=gKmsGEXBu4XJIBnD',
  'https://www.youtube.com/embed/qR4aGJxDlsY?si=wJdJ75KEGcCPehY1',
  'https://www.youtube.com/embed/tXW0_s-kiAI?si=ZII3ZzcQDuoHM-e_',
];

export const dateFormats = [
  'yyyy-MM-dd', // ISO 8601 date only
  'yyyy-MM-dd\'T\'HH:mm:ss.SSSXXX', // ISO 8601 full datetime with timezone
  'yyyy-MM-dd\'T\'HH:mm:ssXXX', // ISO 8601 datetime without milliseconds
  'yyyy/MM/dd',
  'MM/dd/yyyy',
  'MM-dd-yyyy',
  'MMMM d, yyyy',
  'MMM d, yyyy',
  'd MMMM, yyyy',
  'd MMM, yyyy',
  'd MMMM yyyy',
  'd MMM yyyy',
  'd\'th\' MMMM, yyyy',
  'd\'st\' MMMM, yyyy',
  'd\'nd\' MMMM, yyyy',
  'd\'rd\' MMMM, yyyy',
  'd\'th\' MMM, yyyy',
  'd\'st\' MMM, yyyy',
  'd\'nd\' MMM, yyyy',
  'd\'rd\' MMM, yyyy',
  'd\'th\' MMMM yyyy',
  'd\'st\' MMMM yyyy',
  'd\'nd\' MMMM yyyy',
  'd\'rd\' MMMM yyyy',
  'd\'th\' MMM yyyy',
  'd\'st\' MMM yyyy',
  'd\'nd\' MMM yyyy',
  'd\'rd\' MMM yyyy',
  // Add more formats as needed
];
