import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useStytch, useStytchSession } from '@stytch/react';
import { auth, homepage } from 'utils/spaUrls';
import logo from 'assets/darklogo.png';
import {
  Button, Card, CardContent, Grid, Stack, Typography, useMediaQuery,
} from '@mui/material';
import React from 'react';
import theme from 'theme';
import { ReactComponent as GoogleIcon } from 'assets/googleIcon.svg';
import { useNavigate } from 'react-router-dom';
import { retrieve } from 'utils/cacheUtils';
import { CacheKeys } from 'utils/constants';
import authenticateExtension from 'utils/extMessages';
import ApplicationExample from 'pages/Authentication/components/ApplicationExample';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import QuestionExample from 'pages/Authentication/components/QuestionExample';
import CodeEvalExample from 'pages/Authentication/components/CodeEvalExample';


export default function Login() {
  const stytchClient = useStytch();
  const { session } = useStytchSession();
  const navigate = useNavigate();
  const REDIRECT_URL = `${window.location.origin}${auth.landingPad}`;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (session) {
      const stytchResponse = retrieve(CacheKeys.response, { parseJson: true });

      if (stytchResponse) {
        authenticateExtension({
          sessionId: stytchResponse.session.session_id,
          token: stytchResponse.session_jwt,
          email: stytchResponse.user.emails[0].email,
          user: stytchResponse.user,
        });
      }
      navigate(homepage);
    }
    else {
      localStorage.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startLinkedinOAuth = () => stytchClient.oauth.linkedin.start({
    login_redirect_url: REDIRECT_URL,
    signup_redirect_url: REDIRECT_URL,
  });

  const startGoogleOAuth = () => stytchClient.oauth.google.start({
    login_redirect_url: REDIRECT_URL,
    signup_redirect_url: REDIRECT_URL,
  });

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container sx={{ height: '100vh', p: 2 }}>
      <Grid item xs={12} lg={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card
          elevation={0}
          sx={{
            width: '100%', maxWidth: 600, p: 4, backgroundColor: '#ffffff',
          }}
        >
          <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid container alignItems='center' spacing={1} sx={{ mb: 4 }}>
              <Grid item xs={12} sx={{ marginLeft: '-40px', marginBottom: '-20px' }}>
                <Stack direction='row' alignItems='center' spacing={2}>
                  <img src={logo} alt='Logo' style={{ maxHeight: '150px' }} />
                  <Typography variant='h1' color='primary' marginLeft='-40px'>Codei</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h5'>Login or Sign Up</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2' color='text.secondary'>Welcome! Please choose one of the login options</Typography>
              </Grid>
            </Grid>
            <Button
              color='secondary'
              size='large'
              variant='outlined'
              startIcon={<LinkedInIcon sx={{ color: '#0077B5' }} />}
              onClick={startLinkedinOAuth}
              fullWidth
              sx={{ mb: 2 }}
            >
              Continue with LinkedIn
            </Button>
            <Button
              color='secondary'
              size='large'
              variant='outlined'
              startIcon={<GoogleIcon style={{ width: '1em', height: '1em' }} />}
              onClick={startGoogleOAuth}
              fullWidth
              sx={{ mb: 2 }}
            >
              Continue with Google
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        sx={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
        }}
      >
        <Card sx={{
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.primary.dark,
          backgroundImage: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
          pl: isMobile ? 2 : 5,
          pr: isMobile ? 2 : 5,
          pt: isMobile ? 5 : 2,
          pb: isMobile ? 5 : 15,
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          <CardContent sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            p: isMobile ? 2 : 5,
          }}
          >
            <Grid container spacing={3} alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
              <Grid
                item
                xs={12}
                sx={{
                  ...(isMobile && {
                    height: '70%',
                    overflowY: 'auto',
                  }),
                }}
              >
                {value === 0 && (
                <ApplicationExample />
                )}
                {value === 1 && (
                <QuestionExample />
                )}
                {value === 2 && (
                <CodeEvalExample />
                )}
              </Grid>
              <Grid item direction='column' justifyContent='center' alignItems='center'>
                <Grid
                  item
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='styled tabs example'
                    variant='scrollable'
                    scrollButtons='auto'
                    textColor='primary'
                    centered
                    TabIndicatorProps={{ style: { backgroundColor: '#4CAF50', top: 0, height: '2px' } }}
                    sx={{
                      overflowX: 'auto',
                      width: '100%',
                      '.MuiTabs-flexContainer': {
                        display: 'flex',
                        justifyContent: 'flex-start',
                        borderTop: '2px solid #667085',
                        boxSizing: 'border-box',
                        padding: 0,
                      },
                      '.MuiTabs-indicator': {
                        top: 0,
                        left: 0,
                        height: '4px',
                        width: '100%',
                      },
                      '.MuiTab-root': {
                        minHeight: '48px',
                        padding: isMobile ? '0 8px' : '0 16px',
                        '&.Mui-selected': {
                          color: '#FFFFFF',
                        },
                        '&:hover': {
                          color: '#FFFFFF',
                          opacity: 1,
                        },
                      },
                    }}
                  >
                    <Tab label='APPLICATION TRACKING' />
                    <Tab label='QUESTION GENERATION' />
                    <Tab label='CODE EVALUATION' />
                  </Tabs>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                textAlign='center'
                sx={{
                  ml: isMobile ? 0 : 10, mr: isMobile ? 0 : 10,
                }}
              >
                {value === 0 && (
                <Typography variant='body1' color='white'>
                  Easily track your applications by uploading the URL of the job description.
                  Codei&apos;s ApplicationAI model fetches
                  all the relevant data and displays it for you
                </Typography>
                )}
                {value === 1 && (
                <Typography variant='body1' color='white'>
                  Codei&apos;s QuestionAI model generates
                  project-based & technical interview questions
                  tailored to you and the role you are applying to
                </Typography>
                )}
                {value === 2 && (
                <Typography variant='body1' color='white'>
                  Codei&apos;s EvalAI model will score your repository on 45 parameters and provide
                  feedback on how to improve your coding skills and style!
                </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
