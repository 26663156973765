import React from 'react';
import {
  Grid, Box, Typography,
} from '@mui/material';
import EmptyStateSvg from 'assets/EmptyState.svg';


export default function EmptySubmissions() {
  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center' direction='column'>
      <Grid item xs={12}>
        <Box
          component='img'
          src={EmptyStateSvg}
          alt='No Submissions'
          sx={{ height: '148px', width: '148px', marginBottom: '16px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ maxWidth: '605px', textAlign: 'center', margin: '0 auto' }}>
          <Typography variant='h5' gutterBottom>
            No Submissions
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            Track your first submission by just uploading the github URL of your solution.
            Codei&apos;s EvalAI model fetches all the relevant files
            and assesses your solution on 25 different parameters.
            Get insights and data about your tech skills and where you can improve.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
