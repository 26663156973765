import { avatarClasses } from '@mui/material/Avatar';
import type { Components } from '@mui/material/styles';
import { Theme } from '@mui/material';


const MuiCardHeader = {
  defaultProps: { titleTypographyProps: { variant: 'h6' }, subheaderTypographyProps: { variant: 'body2' } },
  styleOverrides: {
    root: { padding: '32px 24px 16px' },
    avatar: {
      [`& .${avatarClasses.root}`]: {
        '--Icon-fontSize': 'var(--icon-fontSize-lg)',
        backgroundColor: '#dcdfe4',
        boxShadow: 'var(--mui-shadows-8)',
        color: 'var(--mui-palette-text-primary)',
      },
    },
  },
} satisfies Components<Theme>['MuiCardHeader'];

export default MuiCardHeader;
