import { RadialBar, RadialBarChart } from 'recharts';
import { Box, Typography } from '@mui/material';
import React from 'react';


type TProps = {
  value: number;
  label?: string;
  isFullCircle?: boolean;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default function ProgressCircle({
  value, label, isFullCircle = false, size = 'md',
}: TProps) {
  // Define angles for full and half circle
  const startAngle = isFullCircle ? 90 : 190;
  const endAngle = isFullCircle ? -270 : -10;

  const fitSize = () => {
    if (size === 'xs') {
      return 28;
    }
    else if (size === 'sm') {
      return 96;
    }
    else if (size === 'md') {
      return 144;
    }
    else if (size === 'lg') {
      return 192;
    }
    else {
      return 240;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        '& .recharts-layer path[name="Empty"]': { display: 'none' },
        '& .recharts-layer .recharts-radial-bar-background-sector': { fill: '#A1E2D9' },
      }}
    >
      <RadialBarChart
        barSize={24}
        data={[{ name: 'Empty', value: 100 }, { name: 'Usage', value }]}
        endAngle={endAngle}
        height={fitSize()}
        innerRadius={fitSize() / 2}
        startAngle={startAngle}
        width={fitSize()}
      >
        <RadialBar
          animationDuration={300}
          background
          cornerRadius={10}
          dataKey='value'
          endAngle={endAngle}
          fill='#15B79F'
          startAngle={startAngle}
        />
      </RadialBarChart>
      {label && (
        <Box
          sx={{
            alignItems: 'center',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <Box sx={{ textAlign: 'center', mt: isFullCircle ? 0 : '-40px' }}>
            <Typography variant='h5'>
              {label}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
