import { Breakpoint } from '@mui/material';


const breakpointTrims: Record<Breakpoint, number> = {
  xs: 20,
  sm: 20,
  md: 15,
  lg: 15,
  xl: 20,
};

const getBreakpoint = (width: number): Breakpoint => {
  if (width < 600) return 'xs';
  if (width < 960) return 'sm';
  if (width < 1280) return 'md';
  if (width < 1920) return 'lg';
  return 'xl';
};

let currentBreakpoint: Breakpoint = getBreakpoint(window.innerWidth);

window.addEventListener('resize', () => {
  currentBreakpoint = getBreakpoint(window.innerWidth);
});


// eslint-disable-next-line import/prefer-default-export
export const trimText = (text: string, concatSize?: number) => {
  let trimLength = breakpointTrims[currentBreakpoint];
  if (concatSize) {
    trimLength = concatSize;
  }

  if (text.length > trimLength) {
    return `${text.slice(0, trimLength)}...`;
  }
  return text;
};
