import { Card, CardHeader } from '@mui/material';
import { PropertyList } from 'components/Common/PropertyList';
import Divider from '@mui/material/Divider';
import { PropertyItem } from 'components/Common/PropertyItem';
import React, { ReactNode } from 'react';


type TProps = {
  title: string,
  details: ({key: string, value: string | undefined} | {key: string, value: ReactNode})[],
}

export default function ApplicationInfoCard({ title, details }: TProps) {
  return (
    <Card>
      <CardHeader
        title={title}
      />
      <PropertyList
        divider={<Divider />}
        orientation='vertical'
        sx={{ '--PropertyItem-padding': '18px 24px' }}
      >
        {details.map((item) => (
          <PropertyItem key={item.key} name={item.key} value={item.value} />
        ))}
      </PropertyList>
    </Card>
  );
}
