import {
  Avatar, Card, CardHeader, Grid, Stack, Tooltip, Typography, useMediaQuery,
} from '@mui/material';
import { GitHub, TaskOutlined } from '@mui/icons-material';
import theme from 'theme';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';
import React from 'react';
import Chip from '@mui/material/Chip';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { ERequestStatus } from 'pages/HomePage/types';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { IQuestion } from 'pages/HomePage/queries';
import { Presence } from 'pages/HomePage/components/Presence';


export default function ExampleQuestionItemCard({ question }: { question: IQuestion }) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const chipLevel = () => {
    if (question.submission === null) {
      return (
        <Chip icon={<PlayCircleIcon color='primary' />} color='default' label='No Submission' size='small' variant='outlined' />
      );
    }
    else if (question.submission && question.submission.request_status === ERequestStatus.FAIL) {
      return (
        <Chip icon={<ErrorIcon color='error' />} color='error' label='Failed Submission' size='small' variant='outlined' />
      );
    }
    else if (question.submission && question.submission.request_status === ERequestStatus.SUCCESS) {
      return (
        <Chip icon={<CheckCircleIcon color='success' />} color='success' label='Completed' size='small' variant='outlined' />
      );
    }
    else {
      return (
        <Chip icon={<AccessTimeIcon color='warning' />} color='warning' label='Processing' size='small' variant='outlined' />
      );
    }
  };

  const presenceLevel = () => {
    if (question.submission === null || question.submission === undefined) {
      return 'saved';
    }
    else if (question.submission && question.submission.request_status === ERequestStatus.FAIL) {
      return 'fail';
    }
    else if (question.submission && question.submission.request_status === ERequestStatus.SUCCESS) {
      return 'success';
    }
    else {
      return 'process';
    }
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        sx={{ pt: '15px' }}
        avatar={(
          <>
            <Avatar sx={{ height: '30px', width: '30px' }}><TaskOutlined /></Avatar>
            <Presence size='small' status={presenceLevel()} />
          </>
                )}
        title={<Typography variant='body2' color='black' ml='-10px' mb='-5px'>{question.title}</Typography>}
        action={isMobile ? '' : chipLevel()}
        subheader={<Typography variant='caption' ml='-10px'>Company: Uber</Typography>}
      />
      <Grid container spacing={2} justifyContent='space-between' alignItems='center' style={{ paddingLeft: '20px', marginBottom: '15px' }}>
        {question.submission && question.submission.repo_url && (
        <Grid item xs={12} lg={6}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <GitHub fontSize='small' style={{ color: theme.palette.text.secondary }} />
            <Link
              component={NavLink}
              to={question.submission.repo_url}
              target='_blank'
            >
              <Typography sx={{ whiteSpace: 'nowrap', color: 'text.secondary' }} variant='body2'>
                {question.submission.repo_url.split('/').pop()}
              </Typography>
            </Link>
          </Stack>
        </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <Stack direction='row' spacing={0.5} alignItems='center'>
            {question.skills.slice(0, isMobile ? 1 : 2).map((skill, index) => (
              <Tooltip title={skill} key={skill}>
                <Chip
                  label={skill}
                  variant='filled'
                  sx={{
                    opacity: '60%',
                    backgroundColor: '#FF950026',
                    color: 'black',
                    height: '25px',
                    fontSize: '10px',
                  }}
                />
              </Tooltip>
            ))}
            {(question.skills.length - (isMobile ? 1 : 2)) > 0 && (
            <Tooltip title={question.skills.slice(1, question.skills.length).join(', ')}>
              <Chip
                color='default'
                label={`+${question.skills.length - (isMobile ? 1 : 2)}`}
                variant='filled'
                sx={{ height: '25px', fontSize: '10px' }}
              />
            </Tooltip>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
