import React, { useState } from 'react';
import {
  Grid,
  Button,
} from '@mui/material';
import Spinner from 'components/Common/Spinner';
import { useGetUserSolutionsList } from 'pages/Submissions/queries';
import CommonDrawer from 'components/Common/CommonDrawer';
import SubmitSolution from 'pages/Submissions/SubmitSolution';
import SubmissionView from 'pages/Submissions/components/SubmissionView';
import { CreationType, ERequestStatus } from 'pages/HomePage/types';
import EmptySubmissions from 'pages/Submissions/components/EmptySubmissions';
import Typography from '@mui/material/Typography';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';
import LoadingCard from 'components/Common/LoadingCard';


export default function SubmissionsPage() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { data: solutionListData, isLoading: solutionListLoading } = useGetUserSolutionsList();

  if (!solutionListData || solutionListLoading) {
    return <Spinner />;
  }

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };


  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} alignItems='flex-end' justifyContent='space-between'>
        <Typography variant='h4'>Submissions</Typography>
        <Button
          variant='contained'
          onClick={() => {
            handleClick();
            trackEvents(EEvents.CLICK, {
              feature: 'Submissions',
              type: 'Button',
              label: 'Track New Submission',
            });
          }}
        >
          Track New Submission
        </Button>
      </Grid>
      {solutionListData.data.length === 0 ? (
        <Grid item xs={12}>
          <EmptySubmissions />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid container spacing={2} direction='column'>
            <Grid item container justifyContent='flex-end'>
              <Button variant='contained' onClick={handleClick}>
                Upload New Submission
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                { solutionListData.data.map((solution) => {
                  if (solution.request_status === ERequestStatus.SAVED
                    || solution.request_status === ERequestStatus.SUCCESS) {
                    return <SubmissionView key={solution.id} solution={solution} />;
                  }
                  else if (solution.request_status === ERequestStatus.FAIL) {
                    return null;
                  }
                  else {
                    return <LoadingCard status={solution.request_status} type={CreationType.SUB} />;
                  }
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {modalOpen && (
      <CommonDrawer
        open={modalOpen}
        title='Submit Solution'
        handleClose={() => setModalOpen(false)}
      >
        <SubmitSolution handleSuccess={handleClose} />
      </CommonDrawer>
      )}
    </Grid>
  );
}
