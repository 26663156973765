import {
  Avatar, Button, Card, CardActions, CardHeader, Grid, Stack, Typography,
} from '@mui/material';
import { GitHub, TaskOutlined } from '@mui/icons-material';
import theme from 'theme';
import Link from '@mui/material/Link';
import { NavLink, useNavigate } from 'react-router-dom';
import CustomChip from 'pages/HomePage/components/CustomChip';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import React from 'react';
import Chip from '@mui/material/Chip';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { ERequestStatus } from 'pages/HomePage/types';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { IQuestion } from 'pages/HomePage/queries';
import { Presence } from 'pages/HomePage/components/Presence';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';


export default function QuestionItemCard({ question }: { question: IQuestion }) {
  const navigate = useNavigate();
  const chipLevel = () => {
    if (question.submission === null) {
      return (
        <Chip icon={<PlayCircleIcon color='primary' />} color='default' label='No Submission' size='small' variant='outlined' />
      );
    }
    else if (question.submission && question.submission.request_status === ERequestStatus.FAIL) {
      return (
        <Chip icon={<ErrorIcon color='error' />} color='error' label='Failed Submission' size='small' variant='outlined' />
      );
    }
    else if (question.submission && question.submission.request_status === ERequestStatus.SUCCESS) {
      return (
        <Chip icon={<CheckCircleIcon color='success' />} color='success' label='Completed' size='small' variant='outlined' />
      );
    }
    else {
      return (
        <Chip icon={<AccessTimeIcon color='warning' />} color='warning' label='Processing' size='small' variant='outlined' />
      );
    }
  };

  const presenceLevel = () => {
    if (question.submission === null || question.submission === undefined) {
      return 'saved';
    }
    else if (question.submission && question.submission.request_status === ERequestStatus.FAIL) {
      return 'fail';
    }
    else if (question.submission && question.submission.request_status === ERequestStatus.SUCCESS) {
      return 'success';
    }
    else {
      return 'process';
    }
  };

  const handleViewQuestion = () => {
    trackEvents(EEvents.CLICK, {
      feature: 'ApplicationDetails - Question',
      type: 'Button',
      label: 'View Question',
    });
    navigate(`/question/${question.id}`);
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        avatar={(
          <>
            <Avatar><TaskOutlined /></Avatar>
            <Presence size='small' status={presenceLevel()} />
          </>
                )}
        title={question.title}
        action={chipLevel()}
        subheader={question.application && question.company ? `Company: ${question?.company?.name}` : ''}
      />
      <Grid container spacing={2} justifyContent='space-between' alignItems='center' style={{ paddingLeft: '20px' }}>
        {question.submission && question.submission.repo_url && (
        <Grid item xs={12} lg={6}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <GitHub fontSize='small' style={{ color: theme.palette.text.secondary }} />
            <Link
              component={NavLink}
              to={question.submission.repo_url}
              target='_blank'
            >
              <Typography sx={{ whiteSpace: 'nowrap', color: 'text.secondary' }} variant='body2'>
                {question.submission.repo_url.split('/').pop()}
              </Typography>
            </Link>
          </Stack>
        </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <CustomChip skills={question.skills} renderNumber={2} />
        </Grid>
      </Grid>
      <CardActions>
        <Button color='secondary' endIcon={<ArrowForwardOutlinedIcon />} size='small' onClick={handleViewQuestion}>
          View question
        </Button>
      </CardActions>
    </Card>
  );
}
