import {
  IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useState } from 'react';
import ExportPDF from 'pages/HomePage/components/ExportPDF';
import { IQuestion } from 'pages/HomePage/queries';


type TProps = {
    title: string,
    onButtonClick: () => void,
    type: 'success' | 'error' | 'warning' | 'default',
    question: IQuestion;
}

export default function QuestionDetailsHeader({
  title, onButtonClick, type, question,
}: TProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const chipText = () => {
    if (type === 'success') {
      return 'Completed';
    }
    else if (type === 'error') {
      return 'Submission failed';
    }
    else if (type === 'warning') {
      return 'Processing';
    }
    else {
      return 'No Submission';
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ alignItems: 'flex-start' }}>
      <Stack direction='row' spacing={2} sx={{ alignItems: 'center', flex: '1 1 auto' }}>
        <div>
          <Stack direction='row' spacing={2} sx={{ alignItems: 'center', flexWrap: 'wrap' }}>
            <Typography variant='h4'>{title}</Typography>
          </Stack>
          <Chip
            icon={<CheckCircleIcon color={type as 'success' | 'error' | 'warning' | 'disabled' | 'action' | 'inherit' | 'primary' | 'secondary' | 'info' | undefined} />}
            label={chipText()}
            size='small'
            variant='outlined'
          />
        </div>
      </Stack>
      <div>
        <Stack direction='row' spacing={2}>
          <div>
            <IconButton onClick={handleMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              <ExportPDF questions={[question]} />
              <MenuItem onClick={onButtonClick}>
                <ListItemIcon>
                  <Delete fontSize='small' />
                </ListItemIcon>
                Delete Question
              </MenuItem>
            </Menu>
          </div>
        </Stack>
      </div>
    </Stack>
  );
}
