import {
  Card, CardActions, CardContent, CardHeader, Grid, IconButton, Stack, Typography,
} from '@mui/material';
import { IQuestion } from 'pages/HomePage/queries';
import { GitHub } from '@mui/icons-material';
import theme from 'theme';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';
import { QuestionCtx } from 'pages/Submissions/SubmitSolution';
import CustomChip from 'pages/HomePage/components/CustomChip';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import React, { useMemo, useState } from 'react';
import URLSubmissionDialog from 'pages/Submissions/components/UrlSubmissionDialog';
import SpinnerButton from 'components/Common/SpinnerButton';
import { ERequestStatus } from 'pages/HomePage/types';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CopyableTextField from 'pages/Questions/components/CopyableTextField';
import { jsonStringParse } from 'pages/Questions/components/QuestionScoreCard';


type TProps = {
  bookmark: boolean;
  question: IQuestion;
  onClick: () => void;
}

export default function QuestionDetailCard({ question, onClick, bookmark }: TProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [submissionProcessing, setSubmissionProcessing] = useState<boolean>(false);
  const contextValue = useMemo(() => ({
    questionId: question.id,
    applicationId: question?.application?.id || '',
  }), [question.id, question.application]);

  React.useEffect(() => {
    if (question.submission && question.submission.id) {
      setSubmissionProcessing(false);
    }
  }, [question]);

  const copyText: string[] = jsonStringParse(question.resume_points || '');
  const prefixedCopyText = copyText.map((text) => `- ${text}`);
  prefixedCopyText.unshift(question.title);

  return (
    <>
      <Card>
        <CardHeader
          title='Description'
          subheader={`Duration: ${question.duration} minutes`}
          action={(
            <IconButton
              onClick={onClick}
            >
              {bookmark ? (<BookmarkIcon fontSize='medium' />) : (<BookmarkBorderIcon fontSize='medium' />)}
            </IconButton>
          )}
        />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant='body1'>{question.description}</Typography>
            </Grid>
            {question.resume_points && (
            <Grid item xs={12}>
              <CopyableTextField initialText={prefixedCopyText} />
            </Grid>
            )}
          </Grid>
        </CardContent>
        <Grid container spacing={2} justifyContent='space-between' alignItems='center' style={{ paddingLeft: '20px' }}>
          {question.submission && question.submission.repo_url ? (
            <Grid item xs={12} md={6}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='body2' color='text.secondary'>Submission: </Typography>
                <GitHub fontSize='small' style={{ color: theme.palette.text.secondary }} />
                <Link
                  component={NavLink}
                  to={question.submission.repo_url}
                  target='_blank'
                >
                  <Typography sx={{ whiteSpace: 'nowrap', color: 'text.secondary' }} variant='body2'>
                    {question.submission.repo_url.split('/').pop()}
                  </Typography>
                </Link>
              </Stack>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <GitHub fontSize='small' style={{ color: theme.palette.text.secondary }} />
                <Typography sx={{ whiteSpace: 'nowrap', color: 'text.secondary' }} variant='body2'>
                  N/A
                </Typography>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography variant='body2' color='text.secondary'>Skills:</Typography>
              <CustomChip skills={question.skills} renderNumber={3} />
            </Stack>
          </Grid>
        </Grid>
        <CardActions>
          <SpinnerButton
            startIcon={<DriveFolderUploadIcon />}
            variant='contained'
            size='medium'
            disabled={Boolean(
              question.submission
                && question.submission.request_status !== ERequestStatus.FAIL,
            )}
            onClick={() => {
              setModalOpen(true);
            }}
            isLoading={submissionProcessing}
          >
            Upload Submission
          </SpinnerButton>
        </CardActions>
      </Card>
      {modalOpen && (
      <QuestionCtx.Provider
        value={contextValue}
      >
        <URLSubmissionDialog
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          onSubmit={() => setSubmissionProcessing(true)}
        />
      </QuestionCtx.Provider>
      )}
    </>
  );
}
