import { Grid, Typography } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import React from 'react';


type TProps = {
    isMobile: boolean,
    note?: string,
    stepperString: string[],
    videoLink: string,
}


export default function ApplicationQuickGuide({
  isMobile, note, stepperString, videoLink,
}: TProps) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant='body2' color='text.secondary'>{note}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stepper orientation='vertical'>
          {stepperString.map((label) => (
            <Step key={label}><StepLabel>{label}</StepLabel></Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='subtitle2'>Refer to video if necessary</Typography>
        <iframe
          width='100%'
          height='255'
          src={videoLink}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerPolicy='strict-origin-when-cross-origin'
          allowFullScreen
        />
      </Grid>
      {isMobile && (
      <Grid item xs={12}>
        <Typography variant='subtitle2'>Please click on the youtube title to expand the video</Typography>
      </Grid>
      )}
    </>
  );
}
