import { Grid } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import {
  useGetQuestion,
  useQuestionBookmark,
  userQuestionQueryKey,
  useDeleteQuestion,
} from 'pages/HomePage/queries';
import Spinner from 'components/Common/Spinner';
import QuestionDetailsHeader from 'pages/Questions/components/QuestionDetailsHeader';
import { ERequestStatus } from 'pages/HomePage/types';
import QuestionDetailCard from 'pages/Questions/components/QuestionDetailCard';
import QuestionScoreCard from 'pages/Questions/components/QuestionScoreCard';
import {
  ReactNode, useContext, useEffect, useState,
} from 'react';
import ScoreLoadingCard from 'pages/Questions/components/ScoreLoadingCard';
import SubmissionInstructionCard from 'pages/Questions/components/SubmissionInstructionCard';
import { useQueryClient } from '@tanstack/react-query';
import { questions } from 'utils/spaUrls';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';
import { AppAlertsCtx } from 'components/Common/AppAlerts';


export default function QuestionDetails() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addAlert } = useContext(AppAlertsCtx);
  const { questionId } = useParams<{ questionId: string }>();
  const questionBookmark = useQuestionBookmark();
  const deleteQuestionHook = useDeleteQuestion();
  const [bookmark, setBookmark] = useState<boolean>(false);
  const { data: questionData, isLoading } = useGetQuestion(questionId || '', {
    queryKey: userQuestionQueryKey(questionId || ''),
    enabled: Boolean(questionId),
  });

  useEffect(() => {
    if (questionData?.submission && questionData?.submission?.error_details) {
      if (questionData?.submission.error_details === 'Invalid GitHub URL') {
        addAlert({
          type: 'error',
          message: 'Invalid GitHub URL, Make Sure Repository is Public',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData]);

  useEffect(() => {
    setBookmark(questionData?.bookmark || false);
  }, [questionData]);

  if (!questionData || isLoading) {
    return <Spinner />;
  }

  const handleButtonClick = () => {
    trackEvents(EEvents.CLICK, {
      feature: 'QuestionDetails - Delete Question',
      type: 'Button',
      label: 'Delete Question',
    });
    deleteQuestionHook.mutate(questionId || '', {
      onSuccess: () => {
        navigate(questions.list);
      },
    });
  };

  const handleBookmarkClick = () => {
    trackEvents(EEvents.CLICK, {
      feature: 'QuestionDetails - Bookmark Question',
      type: 'Button',
      label: 'Bookmark Question',
    });
    questionBookmark.mutate({ question_id: questionId || '', bookmark: !bookmark }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: userQuestionQueryKey(questionId || '') });
      },
    });
  };

  const presenceLevel = (): {type: 'default' | 'error' | 'success' | 'warning', component: ReactNode} => {
    if (questionData.submission === null) {
      return { type: 'default', component: (<SubmissionInstructionCard />) };
    }
    else if (questionData.submission
        && questionData.submission.request_status === ERequestStatus.FAIL) {
      return { type: 'error', component: (<SubmissionInstructionCard />) };
    }
    else if (questionData.submission
        && questionData.submission.request_status === ERequestStatus.SUCCESS) {
      return { type: 'success', component: (<QuestionScoreCard submission={questionData.submission} />) };
    }
    else {
      return { type: 'warning', component: (<ScoreLoadingCard />) };
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionDetailsHeader
          onButtonClick={handleButtonClick}
          title={questionData.title}
          type={presenceLevel().type}
          question={questionData}
        />
      </Grid>
      <Grid item xs={12}>
        <QuestionDetailCard
          question={questionData}
          onClick={handleBookmarkClick}
          bookmark={bookmark}
        />
      </Grid>
      <Grid item xs={12}>
        {presenceLevel().component}
      </Grid>
    </Grid>
  );
}
