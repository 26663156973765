import * as React from 'react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { homepage } from 'utils/spaUrls';
import { FilterButton, FilterPopover, useFilterContext } from 'pages/Questions/components/FilterButton';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';


export interface ApplicationFiltersItems {
    position?: string;
    company?: string;
    skill?: string;
    appliedDate?: string;
}

export type SortDir = 'asc' | 'desc';

export interface CustomersFiltersProps {
    filters?: ApplicationFiltersItems;
    sortDir?: SortDir;
    filterTabs: readonly {
        readonly label: string,
        readonly value: string,
        readonly count: number
    }[],
}

export function ApplicationFilters({ filters = {}, sortDir = 'desc', filterTabs }: CustomersFiltersProps): React.JSX.Element {
  const {
    position, company, skill, appliedDate,
  } = filters;
  const navigate = useNavigate();

  const updateSearchParams = React.useCallback(
    (newFilters: ApplicationFiltersItems, newSortDir: SortDir): void => {
      const searchParams = new URLSearchParams();

      if (newSortDir === 'asc') {
        searchParams.set('sortDir', newSortDir);
      }

      if (newFilters.position) {
        searchParams.set('position', newFilters.position);
      }

      if (newFilters.company) {
        searchParams.set('company', newFilters.company);
      }

      if (newFilters.skill) {
        searchParams.set('skill', newFilters.skill);
      }

      if (newFilters.appliedDate) {
        searchParams.set('job_applied', newFilters.appliedDate);
      }

      navigate(`${homepage}?${searchParams.toString()}`);
    },
    [navigate],
  );

  const handleClearFilters = React.useCallback(() => {
    updateSearchParams({}, sortDir);
  }, [updateSearchParams, sortDir]);

  const handlePositionChange = React.useCallback(
    (value?: string) => {
      updateSearchParams({ ...filters, position: value }, sortDir);
    },
    [updateSearchParams, filters, sortDir],
  );

  const handleCompanyChange = React.useCallback(
    (value?: string) => {
      updateSearchParams({ ...filters, company: value }, sortDir);
    },
    [updateSearchParams, filters, sortDir],
  );

  const handleSkillChange = React.useCallback(
    (value?: string) => {
      updateSearchParams({ ...filters, skill: value }, sortDir);
    },
    [updateSearchParams, filters, sortDir],
  );

  const handleJobAppliedDateChange = React.useCallback(
    (date: string | null) => {
      updateSearchParams({ ...filters, appliedDate: date ?? undefined }, sortDir);
    },
    [updateSearchParams, filters, sortDir],
  );

  const hasFilters = position || company || skill || appliedDate;

  return (
    <div>
      <Tabs sx={{ px: 3, textTransform: 'none' }} value='' variant='scrollable'>
        {filterTabs.map((tab) => (
          <Tab
            icon={<Chip label={tab.count} size='small' variant='filled' color='default' />}
            iconPosition='end'
            key={tab.value}
            label={tab.label}
            sx={{ minHeight: 'auto' }}
            tabIndex={0}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Stack
        direction='row'
        spacing={2}
        sx={{
          alignItems: 'center', flexWrap: 'wrap', px: 3, py: 2,
        }}
      >
        <Stack direction='row' spacing={2} sx={{ alignItems: 'center', flex: '1 1 auto', flexWrap: 'wrap' }}>
          <FilterButton
            displayValue={company}
            label='Company'
            onFilterApply={(value) => {
              handleCompanyChange(value as string);
            }}
            onFilterDelete={() => {
              handleCompanyChange();
            }}
            popover={<CompanyFilterPopover title='Filter by Company' />}
            value={company}
          />
          <FilterButton
            displayValue={position}
            label='Position'
            onFilterApply={(value) => {
              handlePositionChange(value as string);
            }}
            onFilterDelete={() => {
              handlePositionChange();
            }}
            popover={<CompanyFilterPopover title='Filter by Position' />}
            value={position}
          />
          <FilterButton
            displayValue={skill}
            label='Skill'
            onFilterApply={(value) => {
              handleSkillChange(value as string);
            }}
            onFilterDelete={() => {
              handleSkillChange();
            }}
            popover={<CompanyFilterPopover title='Filter by Skill' />}
            value={skill}
          />
          <FilterButton
            displayValue={appliedDate}
            label='Job Applied'
            onFilterApply={(value) => {
              handleJobAppliedDateChange(value as string);
            }}
            onFilterDelete={() => {
              handleJobAppliedDateChange(null);
            }}
            popover={(
              <BasicDatePicker />
                        )}
            value={appliedDate}
          />
          {hasFilters ? <Button onClick={handleClearFilters}>Clear filters</Button> : null}
        </Stack>
      </Stack>
    </div>
  );
}

function CompanyFilterPopover({
  title,
}: {
    title: string,
}): React.JSX.Element {
  const {
    anchorEl, onApply, onClose, open, value: initialValue,
  } = useFilterContext();
  const [value, setValue] = React.useState<string>('');

  React.useEffect(() => {
    setValue((initialValue as string | undefined) ?? '');
  }, [initialValue]);

  const handleApply = () => {
    onApply(value);
  };

  return (
    <FilterPopover anchorEl={anchorEl} onClose={onClose} open={open} title={title}>
      <FormControl>
        <OutlinedInput
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              handleApply();
            }
          }}
          value={value}
        />
      </FormControl>
      <Button
        onClick={() => {
          handleApply();
        }}
        variant='contained'
      >
        Apply
      </Button>
    </FilterPopover>
  );
}

function BasicDatePicker(): React.JSX.Element {
  const {
    anchorEl, onApply, onClose, open, value: initialValue,
  } = useFilterContext();
  const [value, setValue] = React.useState<Dayjs | null>(null);

  React.useEffect(() => {
    setValue(dayjs.isDayjs(initialValue) ? initialValue : null);
  }, [initialValue]);

  const handleApply = React.useCallback(() => {
    if (value && value.isValid()) {
      const formattedDate = value.format('MM-DD-YYYY');
      onApply(formattedDate);
    }
    else {
      onApply(null);
    }
    onClose();
  }, [value, onApply, onClose]);

  return (
    <FilterPopover anchorEl={anchorEl} onClose={onClose} open={open} title='Filter by Date'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label='Select date'
          value={value}
          onChange={(newValue: dayjs.Dayjs | null) => {
            setValue(newValue || null);
          }}
        />
      </LocalizationProvider>
      <Button
        onClick={handleApply}
        variant='contained'
      >
        Apply
      </Button>
    </FilterPopover>
  );
}


