export const auth = {
  landingPad: '/auth/landing-pad',
  login: '/auth/login',
  logout: '/auth/logout',
};

export const applications = {
  list: '/applications/list',
  details: '/application/:applicationId',
};

export const coaching = '/coaching';

export const connections = {
  list: '/connections/list',
};

export const homepage = '/welcome';

export const questions = {
  list: '/questions/list',
  details: '/question/:questionId',
};

export const submissions = '/submissions';

export const baseQueryOptions = {
  retry: false,
};

export const feedback = 'https://codei.convas.io/';
