import { Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import ExampleCodeEvalCard from 'pages/Authentication/components/ExampleCodeEvalCard';
import theme from 'theme';


export default function CodeEvalExample() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center' sx={{ pl: isMobile ? 0 : 5, pr: isMobile ? 0 : 5 }}>
      <Grid item xs={12}>
        <ExampleCodeEvalCard />
      </Grid>
    </Grid>
  );
}
