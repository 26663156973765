import React from 'react';
import {
  Grid, Box, Typography,
} from '@mui/material';
import EmptyStateSvg from 'assets/EmptyState.svg';


export default function EmptyFilters({ title, subtitle } :{title: string, subtitle: string}) {
  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center' direction='column'>
      <Grid item xs={12}>
        <Box
          component='img'
          src={EmptyStateSvg}
          alt='No Submissions'
          sx={{ height: '72px', width: '72px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ maxWidth: '605px', textAlign: 'center', margin: '0 auto' }}>
          <Typography variant='h5' gutterBottom>
            {title}
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            {subtitle}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
