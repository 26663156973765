import React from 'react';
import {
  Grid, Button,
} from '@mui/material';
import {
  ISolution,
} from 'pages/Submissions/queries';
import FlipCard, { TFlipCardProps } from 'pages/HomePage/components/FlipCard';
import { useGetQuestion } from 'pages/HomePage/queries';
import EvaluationDrawer from './EvaluationDrawer';


type TProps = {
  solution: ISolution;
};

export default function SubmissionView({ solution }: TProps) {
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

  const { data: questionData } = useGetQuestion(solution.question_id);

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };
  const frontCard: TFlipCardProps = {
    title: questionData?.title || 'Loading...',
    subheader: `Score: ${solution.overall_score ? `${solution.overall_score}/100` : 'still processing score'}`,
    content: {
      'Evaluation Score': (solution.overall_score ? `${solution.overall_score}/100` : 'still processing score'),
      'Repo URL': (
        <Button variant='outlined' onClick={() => window.open(solution.repo_url, '_blank')}>
          View Repository
        </Button>
      ),
    },
  };

  const backCard: TFlipCardProps = {
    title: 'Solution Details',
    content: {
      'Created At': new Date(solution.created_at).toLocaleDateString(),
      'Repo Type': solution.repo_type,
    },
  };

  return (
    <Grid key={solution.id} item xs={12} md={4} lg={3} xl={2}>
      <FlipCard logo={null} frontSide={frontCard} backSide={backCard} />
      {drawerOpen && (
        <EvaluationDrawer isOpen={drawerOpen} onClose={handleCloseDrawer} solution={solution} />
      )}
    </Grid>
  );
}
