import type { Components } from '@mui/material/styles';
import { Theme } from '@mui/material';


const MuiTooltip = {
  defaultProps: { placement: 'top' },
  styleOverrides: { tooltip: { backdropFilter: 'blur(6px)' } },
} satisfies Components<Theme>['MuiTooltip'];

export default MuiTooltip;
