import {
  Avatar,
  Card, CardContent, CardHeader, Grid, List, ListItem, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TopicIcon from '@mui/icons-material/Topic';
import CodeIcon from '@mui/icons-material/Code';


export default function ScoreLoadingCard() {
  return (
    <Card>
      <CardHeader title='Evaluation in progress' subheader='It takes upto 2 minutes to complete evaluation' />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>We test your solution on the following:</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title='File Structure' avatar={<Avatar><TopicIcon /></Avatar>} />
              <CardContent>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='GitHub Setup' />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='Repository Structure' />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='README.md file' />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='Installation Guide' />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='Constants' />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='Environment Variables' />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader title='Code Correctness' avatar={<Avatar><CodeIcon /></Avatar>} />
              <CardContent>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='Logical Functionality' />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='Error Handling' />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='Time Complexity' />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='Efficient Coding Practices' />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='Code Adaptability' />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecordIcon sx={{ height: '15px' }} />
                    </ListItemIcon>
                    <ListItemText primary='Code Styling' />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
