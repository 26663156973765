import React from 'react';
import {
  Grid, Box, Typography, Button,
} from '@mui/material';
import EmptyStateSvg from 'assets/EmptyState.svg';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';


export default function EmptyApplication({ handleClick }: {handleClick: () => void}) {
  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center' direction='column'>
      <Grid item xs={12}>
        <Box
          component='img'
          src={EmptyStateSvg}
          alt='No Applications'
          sx={{ height: '148px', width: '148px', marginBottom: '16px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ maxWidth: '605px', textAlign: 'center', margin: '0 auto' }}>
          <Typography variant='h5' gutterBottom>
            No Applications
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            Track your first application by just uploading the URL of the job description.
            Codei&apos;s ApplicationAI model fetches all the relevant data and displays it for you.
            Get insights and data about the role instantly.
          </Typography>

        </Box>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant='contained'
          onClick={() => {
            handleClick();
            trackEvents(EEvents.CLICK, {
              feature: 'HomePage - Application',
              type: 'Button',
              label: 'Let\'s add your first application',
            });
          }}
        >
          Let&apos;s add your first application
        </Button>
      </Grid>
    </Grid>
  );
}
