import {
  Avatar, Card, CardContent, CardHeader, IconButton, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';


export default function SubmissionInstructionCard() {
  const supportEmail = 'support@codei.ai';
  const subject = encodeURIComponent('Submission Instructions');
  const body = encodeURIComponent('I am a user at Codei and recently tried to submit a solution for a question. I wanted to get more context into Github repository structuring for my submission.');

  const handleClick = () => {
    trackEvents(EEvents.CLICK, {
      feature: 'QuestionDetails - Contact Us',
      type: 'Button',
      label: 'Contact Us',
    });
    const mailtoLink = `mailto:${supportEmail}?subject=${subject}&body=${body}`;
    window.open(mailtoLink, '_blank');
  };

  return (
    <Card>
      <CardHeader
        title='Instructions'
        subheader='Submission'
        action={(
          <IconButton onClick={handleClick}>
            <SupportAgentIcon />
          </IconButton>
        )}
        avatar={(
          <Avatar>
            <LightbulbIcon />
          </Avatar>
        )}
      />
      <CardContent>
        <Card>
          <CardContent>
            <List>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon sx={{ height: '15px' }} />
                </ListItemIcon>
                <ListItemText primary='Ensure your project is a GitHub Repository. Verify that your repository is public.' />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon sx={{ height: '15px' }} />
                </ListItemIcon>
                <ListItemText primary='Structure your repository with clear and logical directories.
            Use consistent and descriptive names for files and folders.'
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon sx={{ height: '15px' }} />
                </ListItemIcon>
                <ListItemText primary={`Include a README.md file at the root providing a brief description of the project's purpose and functionality. 
            This should also include details on usage of application and necessary commands if any. Highlight the key features of your project. Credit any third-party resources or libraries.`}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon sx={{ height: '15px' }} />
                </ListItemIcon>
                <ListItemText primary={'Utilize environment variables for configuration if necessary and provide an example file named \'.env.example\''} />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}
