import React from 'react';
import {
  Grid, Box, Typography,
} from '@mui/material';
import EmptyStateSvg from 'assets/EmptyState.svg';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';
import SpinnerButton from 'components/Common/SpinnerButton';


export default function EmptyQuestions({
  handleClick,
  isLoading,
}: {handleClick: () => void, isLoading: boolean}) {
  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center' direction='column'>
      <Grid item xs={12}>
        <Box
          component='img'
          src={EmptyStateSvg}
          alt='No Submissions'
          sx={{ height: '72px', width: '72px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ maxWidth: '605px', textAlign: 'center', margin: '0 auto' }}>
          <Typography variant='h5' gutterBottom>
            Let&apos;s start your Job Preparation
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            It looks like no questions have been generated yet. Click the
            button below to kickstart your preparation journey!
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SpinnerButton
          variant='contained'
          isLoading={isLoading}
          onClick={() => {
            handleClick();
            trackEvents(EEvents.CLICK, {
              feature: 'ApplicationDetails - Question',
              type: 'Button',
              label: 'Generate Practice Questions',
            });
          }}
        >
          {isLoading ? 'Processing' : 'Generate Practice Questions'}
        </SpinnerButton>
      </Grid>
    </Grid>
  );
}
