import React from 'react';
import {
  Box, Button, Card, CardActions, CardContent,
  CardHeader, Grid, ListItem, ListItemText, Stack, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import SyncIcon from '@mui/icons-material/Sync';
import { BusinessCenterRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { trimText } from 'pages/HomePage/components/utils';
import ActionButtonGroup from 'components/Common/ActionButtonGroup';
import SpinnerButton from 'components/Common/SpinnerButton';


const FlipCardContainer = styled(Box)({
  perspective: '1000px',
  height: '400px',
  width: '350px',
});

const FlipCardInner = styled(Box)<{ isFlipped: boolean }>(({ isFlipped }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  transformStyle: 'preserve-3d',
  transition: 'transform 0.6s',
  transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
}));

const FlipCardSide = styled(Card)(({ theme: muiTheme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  color: muiTheme.palette.text.primary,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const FlipCardBack = styled(FlipCardSide)({
  transform: 'rotateY(180deg)',
});

type TProps = {
  logo: string | null;
  frontSide: TFlipCardProps,
  backSide: TFlipCardProps,
}

export type TFlipCardProps = {
  title: string;
  subheader?: string;
  content: Record<string, React.ReactNode>;
  primaryButton?: TButtonProps;
  secondaryButton?: TButtonProps;
};

export type TButtonProps = {
  onClick: () => void;
  label: string | React.ReactNode;
  isLoading?: boolean;
};

export default function FlipCard({
  logo, frontSide, backSide,
}: TProps) {
  const [isFlipped, setIsFlipped] = React.useState<boolean>(false);
  const isFlippable = backSide.content && Object.keys(backSide.content).length > 0;

  const handleFlip = () => {
    if (isFlippable) {
      setIsFlipped(!isFlipped);
    }
  };

  return (
    <FlipCardContainer onClick={handleFlip}>
      <FlipCardInner isFlipped={isFlipped}>
        <FlipCardSide>
          <CardHeader
            title={frontSide.title}
            subheader={frontSide.subheader}
            avatar={logo
              ? <Avatar src={logo} /> : <Avatar><BusinessCenterRounded /></Avatar>}
            action={isFlippable ? (<SyncIcon color='disabled' />) : null}
          />
          <CardContent>
            <Stack spacing={1} direction='column'>
              {Object.entries(frontSide.content).map(([label, value]) => (
                <ApplicationDetails label={label} value={value} />
              ))}
            </Stack>
          </CardContent>
          <CardActions>
            <Grid container spacing={2}>
              <Grid item xs={12} justifyItems='space-between'>
                <ActionButtonGroup>
                  {frontSide.secondaryButton && (
                  <Button onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    if (frontSide?.secondaryButton?.onClick) {
                      e.stopPropagation();
                      frontSide.secondaryButton.onClick();
                    }
                  }}
                  >
                    {frontSide.secondaryButton.label}
                  </Button>
                  )}
                  {frontSide?.primaryButton && (
                    <SpinnerButton
                      variant='contained'
                      color='primary'
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        if (frontSide?.primaryButton?.onClick) {
                          e.stopPropagation();
                          frontSide.primaryButton.onClick();
                        }
                      }}
                      isLoading={frontSide.primaryButton.isLoading}
                    >
                      {frontSide.primaryButton.label}
                    </SpinnerButton>
                  )}
                </ActionButtonGroup>
              </Grid>
            </Grid>
          </CardActions>
        </FlipCardSide>
        <FlipCardBack>
          <CardHeader title={backSide.title} subheader={backSide.subheader} />
          <CardContent style={{ flex: 1, maxHeight: '125px', overflow: 'auto' }}>
            <Stack spacing={1} direction='column'>
              {Object.entries(backSide.content).map(([label, value]) => (
                <ApplicationDetails label={label} value={value} />
              ))}
            </Stack>
          </CardContent>
          <CardActions>
            <Grid container spacing={2}>
              <Grid item xs={12} justifyItems='space-between'>
                <ActionButtonGroup>
                  {backSide.primaryButton && (
                    <SpinnerButton
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        if (backSide?.primaryButton?.onClick) {
                          e.stopPropagation();
                          backSide.primaryButton.onClick();
                        }
                      }}
                      isLoading={backSide.primaryButton.isLoading}
                    >
                      <Typography>{backSide.primaryButton.label}</Typography>
                    </SpinnerButton>
                  )}
                  {backSide.secondaryButton && (
                    <Button onClick={backSide.secondaryButton.onClick}>
                      <Typography>{backSide.secondaryButton.label}</Typography>
                    </Button>
                  )}
                </ActionButtonGroup>
              </Grid>
            </Grid>
          </CardActions>
        </FlipCardBack>
      </FlipCardInner>
    </FlipCardContainer>
  );
}

export function ApplicationDetails(
  { label, value }: { label: string, value: string | React.ReactNode },
) {
  const renderObject = (objectValue: string | React.ReactNode) => {
    if (typeof objectValue === 'string' && objectValue.startsWith('http')) {
      return <Link to={objectValue}><Typography variant='body2'>{objectValue}</Typography></Link>;
    }
    else if (typeof objectValue === 'string' && !objectValue.startsWith('http')) {
      return (
        <Tooltip title={objectValue} placement='top-start'><Typography variant='body2'>{trimText(objectValue)}</Typography></Tooltip>
      );
    }
    else {
      return value;
    }
  };
  return (
    <ListItem disableGutters>
      <ListItemText
        disableTypography
        primary={(
          <Grid container spacing={2} justifyContent='space-between' alignItems='center'>
            <Grid item xs={6}>
              <Typography noWrap fontWeight='bold' variant='body2'>{label}</Typography>
            </Grid>
            <Grid item xs={6}>
              {renderObject(value)}
            </Grid>
          </Grid>
        )}
      />
    </ListItem>
  );
}
