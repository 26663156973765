const baseUrl = process.env.REACT_APP_SERVER_URL as string;

const sample = {
  status: `${baseUrl}/status`,
};

const application = {
  list: `${baseUrl}/api/v1/users/applications/list`,
  create: `${baseUrl}/api/v1/users/applications`,
  delete: (applicationId: string) => `${baseUrl}/api/v1/users/applications/${applicationId}`,
  details: (applicationId: string) => `${baseUrl}/api/v1/users/applications/${applicationId}`,
};

const user = {
  details: `${baseUrl}/api/v1/users`,
  create: `${baseUrl}/api/v1/users`,
};

const questions = {
  details: (applicationId: string) => `${baseUrl}/api/v1/questions/${applicationId}`,
  create: `${baseUrl}/api/v1/questions/generate`,
  list: `${baseUrl}/api/v1/users/questions`,
  getQuestion: (questionId: string) => `${baseUrl}/api/v1/questions/single/${questionId}`,
  bookmark: (questionId: string) => `${baseUrl}/api/v1/questions/${questionId}/bookmark`,
  delete: (questionId: string) => `${baseUrl}/api/v1/questions/${questionId}`,

};

export const submissions = {
  list: `${baseUrl}/api/v1/solutions/list`,
  latest: (solutionId: string) => `${baseUrl}/api/v1/solutions/${solutionId}/latest`,
  create: (questionId: string) => `${baseUrl}/api/v1/solutions/${questionId}/create`,
  delete: (solutionId: string) => `${baseUrl}/api/v1/solutions/${solutionId}`,
};

const coaching = {
  createInterest: `${baseUrl}/api/v1/coaching/interested-user`,
};

export {
  application, user, coaching, questions, sample,
};
