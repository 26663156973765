export enum EPosition {
    POSITION_FULL_STACK = 'full_stack',
    POSITION_FRONTEND = 'frontend',
    POSITION_BACKEND = 'backend',
    POSITION_DEVOPS = 'devops',
    POSITION_DATA_SCIENCE = 'data_science',
    POSITION_SOFTWARE_ENGINEER = 'software_engineer',
    POSITION_OTHER = 'other',
    POSITION_DEFAULT = 'none',
}

export enum EExperience {
    EXPERIENCE_ENTRY = 'entry',
    EXPERIENCE_INTERN = 'intern',
    EXPERIENCE_JUNIOR = 'junior',
    EXPERIENCE_OTHER = 'other',
    EXPERIENCE_SENIOR = 'senior',
    EXPERIENCE_STAFF = 'staff',
}

export enum ERequestStatus {
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
    SAVED = 'SAVED',
    PROCESSING = 'PROCESSING',
}

export enum ESource {
  CHROME_EXTENSION = 'CHROME_EXTENSION',
  TEXT_INPUT = 'TEXT_INPUT',
  URL = 'URL',
}

export enum EGitSource {
  URL = 'URL',
  GITHUB = 'GITHUB',
}

export enum ESubmitStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum CreationType {
  SUB = 'submission',
  APP = 'application',
}
