import React, { useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  SxProps, Theme, IconButton,
} from '@mui/material';
import RepoSubmission from 'pages/Submissions/RepoSubmission';
import useSolutionSubmission from 'pages/Submissions/useSolutionSubmission';
import { EGitSource } from 'pages/HomePage/types';
import { QuestionCtx } from 'pages/Submissions//SubmitSolution';
import CloseIcon from '@mui/icons-material/Close';


type URLSubmissionDialogProps = {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onSubmit: () => void;
};

export default function URLSubmissionDialog({
  open, onClose, onCancel, onSubmit,
}: URLSubmissionDialogProps) {
  const ctx = useContext(QuestionCtx);
  const { questionId } = ctx;
  const {
    createSolutionHook,
  } = useSolutionSubmission({ onSuccessCallback: onClose });

  const onRepoSelect = (url: string) => {
    if (questionId) {
      createSolutionHook.mutate({
        questionId,
        git_url: url,
        url_type: EGitSource.URL,
      });
      onSubmit();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: dialogPaperStyle,
      }}
    >
      <DialogTitle>
        Submit Solution URL
        <IconButton
          aria-label='close'
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <RepoSubmission
          onRepoSubmit={onRepoSelect}
          disabled={!questionId}
        />
      </DialogContent>
    </Dialog>
  );
}

const dialogPaperStyle: SxProps<Theme> = {
  padding: (theme) => `${theme.spacing(2)}`,
  width: '500px',
};
