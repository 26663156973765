import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Card, Grid, Typography, Divider,
} from '@mui/material';
import { CustomersFilters, Filters } from 'pages/Questions/components/QuestionFilters';
import { IQuestion, useGetUserQuestions } from 'pages/HomePage/queries';
import Spinner from 'components/Common/Spinner';
import QuestionItemCard from 'pages/HomePage/components/QuestionItemCard';
import EmptyQuestions from 'pages/HomePage/components/EmptyQuestions';
import { homepage } from 'utils/spaUrls';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';
import EmptyFilters from 'pages/HomePage/components/EmptyFilters';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function QuestionsList() {
  const query = useQuery();
  const navigate = useNavigate();
  const { data: questionData, isLoading } = useGetUserQuestions();

  const company = query.get('company') || undefined;
  const status = query.get('status') || undefined;
  const skill = query.get('skill') || undefined;
  const sortDir = query.get('sortDir') as 'asc' | 'desc' || 'desc';

  if (!questionData || isLoading || !questionData.data) {
    return <Spinner />;
  }

  const handleClick = () => {
    trackEvents(EEvents.CLICK, {
      feature: 'QuestionsList - Application',
      type: 'Button',
      label: 'Add new application',
    });
    navigate(homepage);
  };

  const tabs = [
    { label: 'All', value: '', count: questionData.data.length },
    { label: 'To Do', value: 'pending', count: questionData.data.filter((question) => question.submission === null).length },
    { label: 'Completed', value: 'completed', count: questionData.data.filter((question) => question.submission !== null).length },
  ] as const;
  const filteredQuestions = applyFilters(questionData.data, { company, status, skill });
  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} alignItems='flex-end' justifyContent='space-between'>
        <Typography variant='h4'>Questions</Typography>
      </Grid>
      {questionData.data.length === 0 ? (
        <Grid item xs={12}>
          <EmptyQuestions handleClick={handleClick} isLoading={false} />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Card>
              <CustomersFilters
                filters={{ company, status, skill }}
                sortDir={sortDir}
                filterTabs={tabs}
              />
              <Divider />
            </Card>
          </Grid>
          <Grid item xs={12}>
            {filteredQuestions.length === 0 ? (
              <Grid container spacing={2} direction='column'>
                <Grid item xs={12} />
                <Grid item xs={12}>
                  <EmptyFilters
                    title={status === 'pending' ? 'No Questions' : 'No Submissions'}
                    subtitle={status === 'pending' ? 'Add more applications to practice a wider range of questions. The more you practice, the more confident you\'ll become.' : 'Enhance your coding proficiency by diving into our practice questions. Receive detailed evaluations to help you improve. Begin your journey to becoming a better coder today!'}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} direction='column'>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {filteredQuestions.map((question) => (
                      <Grid item xs={12} md={6}>
                        <QuestionItemCard key={question.id} question={question} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
}

function applyFilters(row: IQuestion[], { company, status, skill }: Filters): IQuestion[] {
  return row.filter((item) => {
    if (company) {
      if (!item.company?.name.toLowerCase().includes(company.toLowerCase())) {
        return false;
      }
    }

    if (status) {
      if (status === 'pending' && item.submission !== null) {
        return false;
      }
      if (status === 'completed' && item.submission === null) {
        return false;
      }
    }

    if (skill) {
      const lowerCaseSkill = skill.toLowerCase();
      const skillsLowerCase = item.skills.map((s) => s.toLowerCase());
      if (!skillsLowerCase.includes(lowerCaseSkill)) {
        return false;
      }
    }

    return true;
  });
}
