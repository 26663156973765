import Grid from '@mui/material/Grid';
import React from 'react';
import { IQuestion, useGetApplicationQuestions } from 'pages/HomePage/queries';
import {
  FormControl, InputLabel, MenuItem, Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useGetUserSolutionsList } from '../queries';


type TProps = {
  applicationId: string;
  currentValue: string;
  onSelect: (questionId: string) => void;
  disabled?: boolean;
};

export default function QuestionSelection({
  applicationId,
  onSelect,
  disabled,
  currentValue,
}: TProps) {
  const { data: applicationQuestions } = useGetApplicationQuestions(applicationId);

  const { data: solutionListData } = useGetUserSolutionsList();

  const isQuestionSolved = (questionId: string) => solutionListData?.data
    .some((solution) => solution.question_id === questionId);

  const handleChange = (event: SelectChangeEvent) => {
    onSelect(event.target.value as string);
  };

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={12} md={10} lg={10}>
        <FormControl fullWidth sx={{ mt: 3 }}>
          <InputLabel id='questions-select-label'>Question</InputLabel>
          <Select
            disabled={disabled || !applicationQuestions}
            labelId='application-select-label'
            label='Question'
            onChange={handleChange}
            value={currentValue}
          >
            {applicationQuestions?.data.map((question: IQuestion) => {
              const isSolved = isQuestionSolved(question.id);
              return (
                <MenuItem key={question.id} value={question.id} disabled={isSolved}>
                  <Typography variant='body1'>{question.title}</Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Grid />
      </Grid>
    </Grid>
  );
}
