import {
  Typography, Box,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import ApplicationSelection from 'pages/Submissions/components/ApplicationSelection';
import QuestionSelection from 'pages/Submissions/components/QuestionSelection';
import RepoSubmission from 'pages/Submissions/RepoSubmission';
import { EGitSource } from 'pages/HomePage/types';
import useSolutionSubmission from 'pages/Submissions/useSolutionSubmission';
import DrawerStepper from './components/DrawerStepper';


type TProps = {
  handleSuccess: () => void;
};

export type TQuestionCtx = {
  questionId: string;
  applicationId: string;
};

export const QuestionCtx = React.createContext<Partial<TQuestionCtx>>({});

export default function SubmitSolution({ handleSuccess }: TProps) {
  const [, setRepoUrl] = useState('');
  const ctx = React.useContext(QuestionCtx);
  const [applicationId, setApplicationId] = React.useState<string | undefined>(ctx.applicationId);
  const [questionId, setQuestionId] = React.useState<string | undefined>(ctx.questionId);
  const [helperText, setHelperText] = useState<string | undefined>();
  const [, setIsStepOneComplete] = useState(false);
  const [, setIsStepTwoComplete] = useState(false);


  const {
    createSolutionHook,
  } = useSolutionSubmission({ onSuccessCallback: handleSuccess });

  useEffect(() => {
    if (ctx.applicationId) {
      setApplicationId(ctx.applicationId);
      setIsStepOneComplete(true);
    }
    if (ctx.questionId) {
      setQuestionId(ctx.questionId);
      setIsStepTwoComplete(true);
    }
  }, [ctx.applicationId, ctx.questionId]);

  const onRepoSelect = (url: string) => {
    setRepoUrl(url);
    if (questionId) {
      createSolutionHook.mutate({
        questionId,
        git_url: url,
        url_type: EGitSource.URL,
      });
    }
    else {
      setHelperText('Please select a question first.');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <DrawerStepper
        StepOne={<ApplicationSelection onSelect={setApplicationId} currentSelection={applicationId || ''} />}
        StepTwo={<QuestionSelection applicationId={applicationId || ''} onSelect={setQuestionId} disabled={!applicationId} currentValue={questionId || ''} />}
        StepThree={<RepoSubmission onRepoSubmit={onRepoSelect} disabled={!questionId} />}
      />
      {helperText && <Typography color='error'>{helperText}</Typography>}
    </Box>
  );
}
