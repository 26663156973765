import {
  Button,
  Card, CardActions, CardContent, CardHeader, Chip,
  Divider, Grid, IconButton, List, ListItem, ListItemText, Stack, Tooltip, Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Avatar from '@mui/material/Avatar';
import {
  IApplication,
} from 'pages/HomePage/queries';
import { positionParsed } from 'pages/HomePage/constants';
import {
  BusinessCenterRounded,
} from '@mui/icons-material';
import React from 'react';
import stringToDate from 'components/Common/Date';
import { Presence } from 'pages/HomePage/components/Presence';
import SpinnerButton from 'components/Common/SpinnerButton';
import { trimText } from 'pages/HomePage/components/utils';


type TProps = {
    application: IApplication;
}

export default function ExampleApplicationCard({ application }: TProps) {
  return (
    <Grid key={application.id} item>
      <Card style={{ width: '100%', height: '100%' }}>
        <CardHeader
          sx={{ pb: 0.5, mt: '-15px' }}
          action={(
            <div>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </div>
                    )}
          avatar={application?.company?.logo
            ? (
              <>
                <Avatar src={application.company.logo} sx={{ width: '30px', height: '30px' }} />
                <Presence size='small' status='success' />
              </>
            )
            : (
              <>
                <Avatar>
                  <BusinessCenterRounded />
                </Avatar>
                <Presence size='small' status='success' />
              </>
            )}
          subheader={<Typography variant='caption'>{`Position: ${positionParsed.parse(application.role).title}`}</Typography>}
          title={(
            <Tooltip title={application.company.name} placement='top-start'>
              <Typography variant='subtitle2'>{trimText(application.company.name)}</Typography>
            </Tooltip>
                    )}
          titleTypographyProps={{ sx: { mb: '-5px' } }}
          subheaderTypographyProps={{ sx: { mt: '0px' } }}
        />
        <CardContent sx={{ py: 1.5 }}>
          <List disablePadding>
            <ListItem disableGutters dense>
              <ListItemText
                disableTypography
                primary={(
                  <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
                    <Grid item xs={6}>
                      <Typography noWrap fontWeight='bold' variant='caption' sx={{ margin: 0 }}>Job Applied</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='caption' sx={{ margin: 0 }}>{stringToDate({ dateString: application.job_applied, formatStr: 'dd/MM/yyyy' })}</Typography>
                    </Grid>
                  </Grid>
                                )}
              />
            </ListItem>
            <ListItem disableGutters dense>
              <ListItemText
                disableTypography
                primary={(
                  <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
                    <Grid item xs={6}>
                      <Typography noWrap fontWeight='bold' variant='caption' sx={{ margin: 0 }}>Skills</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack direction='row' spacing={0.5} alignItems='center'>
                        {application.skills.slice(0, 1).map((skill, index) => (
                          <Tooltip title={skill} key={skill}>
                            <Chip
                              label={trimText(skill, 10)}
                              variant='filled'
                              sx={{
                                opacity: '60%',
                                backgroundColor: '#FF950026',
                                color: 'black',
                                height: '25px',
                                fontSize: '10px',
                              }}
                            />
                          </Tooltip>
                        ))}
                        {(application.skills.length - 1) > 0 && (
                        <Tooltip title={application.skills.slice(1, application.skills.length).join(', ')}>
                          <Chip
                            color='default'
                            label={`+${application.skills.length - 1}`}
                            variant='filled'
                            sx={{ height: '25px', fontSize: '10px' }}
                          />
                        </Tooltip>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                                )}
              />
            </ListItem>
            <ListItem disableGutters dense>
              <ListItemText
                disableTypography
                primary={(
                  <Grid container spacing={1} justifyContent='space-between' alignItems='center'>
                    <Grid item xs={6}>
                      <Typography noWrap fontWeight='bold' variant='caption' sx={{ margin: 0 }}>Questions</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <SpinnerButton
                        color='primary'
                        variant='outlined'
                        size='small'
                        sx={{ width: '100px' }}
                      >
                        <Typography variant='caption' fontSize='10px'>View Questions</Typography>
                      </SpinnerButton>
                    </Grid>
                  </Grid>
                                )}
              />
            </ListItem>
          </List>
        </CardContent>
        <Divider />
        <CardActions sx={{ height: '40px' }}>
          <Button color='secondary' endIcon={<ArrowForwardOutlinedIcon sx={{ height: '15px' }} />} size='small' sx={{ fontSize: '10px' }}>
            View Application
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
