import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useStytchSession, useStytchUser } from '@stytch/react';
import SideNav from 'components/Layouts/SideNav';
import { useMediaQuery } from '@mui/material';


interface ISideNavCtx {
  drawerWidth: string;
  isFullNav: boolean;
  setIsFullNav: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
  mobileOpen: boolean;
  setMobileOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export const SideNavCtx = React.createContext<Partial<ISideNavCtx>>({});

export default function Borders({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  const { session } = useStytchSession();
  const { user } = useStytchUser();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);

  const [isFullNav, setIsFullNav] = React.useState<boolean>(true);
  const desktopWidth = isFullNav ? theme.custom.sidebarWidth : theme.custom.smSidebarWidth;
  const drawerWidth = isMobile ? theme.custom.mobileSideBarWidth : desktopWidth;

  const sideNavCtxValue = React.useMemo(
    () => ({
      drawerWidth, isFullNav, setIsFullNav, isMobile, setMobileOpen, mobileOpen,
    }),
    [drawerWidth, isFullNav, mobileOpen, setMobileOpen, isMobile],
  );
  if (!session || !user) {
    return <Box>{children}</Box>;
  }

  return (
    <SideNavCtx.Provider value={sideNavCtxValue}>
      <Box component='nav' mx={isMobile ? '0' : '10px'} mt={isMobile ? '0' : `calc(${theme.custom.appBarHeight} + 15px)`}>
        <SideNav />
        <Box
          component='main'
          ml={isMobile ? '0' : `calc(${drawerWidth} + 45px)`}
          mr={isMobile ? '0' : '45px'}
          mt={isMobile ? `calc(${theme.custom.appBarHeight} + 10px)` : '0'}
          p={isMobile ? '16px' : '0'}
          pb={isMobile ? '16px' : '24px'}
        >
          {children}
        </Box>
      </Box>
    </SideNavCtx.Provider>
  );
}
