import { paperClasses } from '@mui/material/Paper';
import type { Components } from '@mui/material/styles';
import { Theme } from '@mui/material';


const MuiCard = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: '20px',
      [`&.${paperClasses.elevation1}`]: {
        boxShadow:
            theme.palette.mode === 'dark'
              ? '0 5px 22px 0 rgba(0, 0, 0, 0.24), 0 0 0 1px rgba(255, 255, 255, 0.12)'
              : '0 5px 22px 0 rgba(0, 0, 0, 0.04), 0 0 0 1px rgba(0, 0, 0, 0.06)',
      },
    }),
  },
} satisfies Components<Theme>['MuiCard'];

export default MuiCard;
