import React from 'react';
import {
  Drawer, Typography, Button, Box,
} from '@mui/material';
import { ISolution } from 'pages/Submissions/queries';


type TProps = {
  isOpen: boolean;
  onClose: () => void;
  solution: ISolution;
};

export default function EvaluationDrawer({ isOpen, onClose, solution }: TProps) {
  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: 300, padding: 2 }}>
        <Typography variant='h6'>
          {solution.question_str}
          {' '}
          - Analysis
        </Typography>
        <Typography variant='subtitle1'>
          Overall Score:
          {solution.overall_score}
        </Typography>

        <Box mt={2}>
          <Button onClick={onClose} variant='outlined' fullWidth>
            Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
