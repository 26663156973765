const authentication = {
  StytchTokenType: 'stytch_token_type',
  StytchToken: 'token',
  Oauth: 'oauth',
  SessionDurationMinutes: 60,
  Google: 'Google',
  Linkedin: 'LinkedIn',
};

export default authentication;
