import type { Components } from '@mui/material/styles';
import { Theme } from '@mui/material';


const MuiListItemButton = {
  defaultProps: { disableRipple: true },
  styleOverrides: { root: { gap: 'var(--ListItem-gap)' } },
} satisfies Components<Theme>['MuiListItemButton'];

export default MuiListItemButton;
