import {
  Card, CardContent, CardHeader, Grid, Typography,
} from '@mui/material';
import { IQuestion } from 'pages/HomePage/queries';
import React from 'react';
import QuestionItemCard from 'pages/HomePage/components/QuestionItemCard';


type TProps = {
  questions: IQuestion[]
}

export default function ApplicationQuestionsCard({ questions }: TProps) {
  return (
    <Card>
      <CardHeader
        title={<Typography variant='h5'>Questions</Typography>}
      />
      <CardContent sx={{ pb: '8px' }}>
        <Grid container spacing={2}>
          {questions.map((question) => (
            <Grid item xs={12}>
              <QuestionItemCard key={question.id} question={question} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
