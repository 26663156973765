import {
  Card, CardContent, CardHeader,
  Grid, Stack, Typography, useMediaQuery,
} from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ProgressCircle from 'pages/Authentication/components/ProgressCircle';
import spinner from 'assets/spinner.svg';
import theme from 'theme';


export default function ExampleCodeEvalCard() {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card>
      <CardHeader
        sx={{ pb: '0px' }}
        title={<Typography variant='h5'>Code Evaluation</Typography>}
      />
      <CardContent>
        <Grid container spacing={isMobile ? 1 : 2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                sx={{ pt: isMobile ? 1 : 2, pb: isMobile ? 1 : 2 }}
                title='File Structure'
                titleTypographyProps={{ fontSize: isMobile ? '0.9rem' : 'auto' }}
                action={(
                  <Grid container spacing={isMobile ? 1 : 2} alignItems='center' justifyContent='center'>
                    <Grid item style={{ paddingTop: '22px' }}>
                      <CheckCircleIcon fontSize='small' color='success' />
                    </Grid>
                    <Grid item>
                      <Typography variant={isMobile ? 'body2' : 'body1'}>6 tasks</Typography>
                    </Grid>
                    <Grid item style={{ paddingTop: '22px' }}>
                      <KeyboardArrowDownIcon fontSize='small' />
                    </Grid>
                  </Grid>
                )}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                sx={{ pt: isMobile ? 1 : 2, pb: isMobile ? 1 : 2 }}
                title='Code Correctness'
                titleTypographyProps={{ fontSize: isMobile ? '0.9rem' : 'auto' }}
                action={(
                  <Grid container spacing={isMobile ? 1 : 2} alignItems='center' justifyContent='center'>
                    <Grid item style={{ paddingTop: '18px' }}>
                      <ProgressCircle value={60} isFullCircle size='xs' />
                    </Grid>
                    <Grid item style={{ paddingLeft: isMobile ? '3px' : '14px' }}>
                      <Typography variant={isMobile ? 'body2' : 'body1'}>6 tasks</Typography>
                    </Grid>
                    <Grid item style={{ paddingTop: '22px' }}>
                      <KeyboardArrowDownIcon fontSize='small' />
                    </Grid>
                  </Grid>
                        )}
              />
              <CardContent style={{ paddingBottom: '16px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack direction='row' spacing={1}>
                      <CheckCircleOutlineIcon color='success' fontSize='small' />
                      <Typography variant='caption'>Logical Functionality</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction='row' spacing={1}>
                      <CheckCircleOutlineIcon color='success' fontSize='small' />
                      <Typography variant='caption'>Error Handling</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction='row' spacing={1}>
                      <CheckCircleOutlineIcon color='success' fontSize='small' />
                      <Typography variant='caption'>Time Complexity</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction='row' spacing={1}>
                      <CheckCircleOutlineIcon color='success' fontSize='small' />
                      <Typography variant='caption'>Efficient Coding Practices</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction='row' spacing={1}>
                      <img src={spinner} alt='Spinner' />
                      <Typography variant='caption'>Code Adaptability</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction='row' spacing={1}>
                      <img src={spinner} alt='Spinner' />
                      <Typography variant='caption'>Code Styling</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
