import {
  Grid, CircularProgress, FormControl, InputLabel, MenuItem,
  Typography,
} from '@mui/material';
import { IApplication, useGetUserApplicationList } from 'pages/HomePage/queries';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { positionParsed } from 'pages/HomePage/constants';


type TProps = {
  onSelect: (applicationId: string) => void;
  currentSelection: string;
};

export default function ApplicationSelection({ onSelect, currentSelection }: TProps) {
  const {
    data: applicationListData,
    isLoading: applicationListLoading,
  } = useGetUserApplicationList();

  const handleChange = (event: SelectChangeEvent) => {
    onSelect(event.target.value as string);
  };

  if (!applicationListData || applicationListLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2} justifyContent='center'>
      <Grid item xs={12} md={10} lg={10}>
        <FormControl fullWidth sx={{ mt: 3 }}>
          <InputLabel id='application-select-label'>Application</InputLabel>
          <Select
            labelId='application-select-label'
            label='Application'
            onChange={handleChange}
            value={currentSelection}
          >
            {applicationListData.data.filter((app:IApplication) => (app.request_status === 'SUCCESS')).map((successApps:IApplication) => (
              <MenuItem key={successApps.id} value={successApps.id}>
                <Typography variant='body1'>
                  {`${successApps.company.name} : ${positionParsed.parse(successApps.role).title}`}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid />
      </Grid>
    </Grid>
  );
}
