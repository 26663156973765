import React from 'react';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';


const moveA = keyframes`
  0% { top: 4vh; }
  5% { top: 6.5vh; left: 0; }
  10% { top: 6.5vh; left: 3vh; }
  15% { top: 4vh; left: 3vh; }
  100% { top: 4vh; left: 3vh; }
`;

const moveB = keyframes`
  0%, 5% { top: 4vh; left: 3vh; }
  12% { top: 1.5vh; left: 3vh; }
  20% { top: 1.5vh; left: 6vh; }
  25% { top: 4vh; left: 6vh; }
  100% { top: 4vh; left: 6vh; }
`;

const moveC = keyframes`
  0%, 15% { top: 4vh; left: 6vh; }
  25% { top: 6.5vh; left: 6vh; }
  35% { top: 6.5vh; left: 9vh; }
  40% { top: 4vh; left: 9vh; }
  100% { top: 4vh; left: 9vh; }
`;

const moveE = keyframes`
  0% { top: 0; left: 0; }
  10% { top: 4vh; left: 0; }
  100% { top: 4vh; left: 0; }
`;

const Square = styled(Box)`
  width: 2vh;
  height: 2vh;
  border-radius: 0.4vh;
  position: absolute;
  margin: 1vh;

  &.A {
    left: 0;
    top: 4vh;
    background-color: #31473A;
    animation: ${moveA} 6s ease-in-out infinite 0.25s alternate;
  }

  &.B {
    left: 3vh;
    top: 4vh;
    background-color: #26382E;
    animation: ${moveB} 6s ease-in-out infinite 0.5s alternate;
  }

  &.C {
    left: 6vh;
    top: 4vh;
    background-color: #1c2921;
    animation: ${moveC} 6s ease-in-out infinite 0.6s alternate;
  }

  &.E {
    left: 0;
    top: 0;
    background-color: #3B5144;
    animation: ${moveE} 6s ease-in-out infinite alternate;
  }
`;

const LoaderWrapper = styled(Box)`
  position: relative;
  width: 14vh;
  height: 8vh;
`;

export default function SquareLoader() {
  return (
    <LoaderWrapper>
      <Square className='A' />
      <Square className='B' />
      <Square className='C' />
      <Square className='E' />
    </LoaderWrapper>
  );
}
