import {
  Card, CardContent, CardHeader, Grid,
  Link, List, ListItem, ListItemIcon, Stack, Typography,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { NavLink } from 'react-router-dom';


export default function LeetCodePractice({ questions }: {questions: string[]}) {
  const cleanUrl = (url: string): string => {
    let trimmed = url.trim();
    if (trimmed.startsWith('"') && trimmed.endsWith('"')) {
      trimmed = trimmed.substring(1, trimmed.length - 1);
    }
    return trimmed;
  };

  const urlToTitle = (url: string): string => {
    const index = url.indexOf('problems/') + 'problems/'.length;
    const pathSegment = url.substring(index);
    const relevantPart = pathSegment.split('/')[0];
    return relevantPart
      .replace(/-/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title='LeetCode Practice Questions' />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List disablePadding>
              {questions.map((question, index) => (
                <ListItem key={question}>
                  <ListItemIcon>
                    <FiberManualRecordIcon sx={{ height: '10px' }} />
                  </ListItemIcon>
                  <Link
                    component={NavLink}
                    to={cleanUrl(question)}
                    target='_blank'
                  >
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Typography>
                        {urlToTitle(cleanUrl(question))}
                      </Typography>
                      <OpenInNewIcon fontSize='small' />
                    </Stack>
                  </Link>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
