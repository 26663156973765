import React, { useMemo, useState } from 'react';
import { ESource } from 'pages/HomePage/types';
import {
  Dialog,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
} from '@mui/material';
import {
  nonCrawlableDomains, quickGuideNote, quickGuideSteps, quickGuideVideoLinks,
} from 'pages/HomePage/constants';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';
import SpinnerButton from 'components/Common/SpinnerButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ApplicationQuickGuide from 'pages/HomePage/components/ApplicationQuickGuide';


type TProps = {
  isOpen: boolean;
  onClose: () => void;
  isMobile: boolean
  title: string;
  confirm: (value: string, source: ESource) => void;
  disableConfirm: boolean;
}

export default function ApplicationModal({
  isOpen,
  onClose,
  title,
  confirm,
  disableConfirm,
  isMobile,
}: TProps) {
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState<string | undefined>();
  const [urlValue, setUrlValue] = useState<string | undefined>();
  const [pastedText, setPastedText] = useState<string>('');
  const [source, setSource] = useState<ESource>(ESource.TEXT_INPUT);
  const chromeExtension = 'https://chromewebstore.google.com/detail/codei/bogkpiipdjalohclonecnccbggdnldai';
  const memoizedUrlValue = useMemo(() => urlValue, [urlValue]);
  const memoizedPastedText = useMemo(() => pastedText, [pastedText]);

  const handleUrlInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUrlValue(value);
    setSource(ESource.URL);
    validateUrlInput(value);
  };

  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleExtensionClick = () => {
    trackEvents(EEvents.CLICK, {
      feature: 'HomePage - Application',
      type: 'Button',
      label: 'Track using chrome extension',
    });
    window.open(chromeExtension, '_blank');
  };

  const handlePastedTextInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setSource(ESource.TEXT_INPUT);
    setPastedText(value);
  };

  const validateUrlInput = (value: string) => {
    const cannotBeCrawled = nonCrawlableDomains.some((url) => value.includes(url));
    if (cannotBeCrawled) {
      setError(true);
      setHelperText('This URL cannot be crawled. Try to copy and paste your JD instead.');
    }
    else {
      setError(false);
      setHelperText('');
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth='lg'
      PaperProps={{
        sx: { width: '71.5%' },
      }}
    >
      <Box display='flex' flexDirection={isMobile ? 'column' : 'row'}>
        <Box flex={1} paddingY={4} paddingX={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>{title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size='medium'
                label='Job Description URL'
                value={memoizedUrlValue}
                onChange={handleUrlInputChange}
                error={error}
                helperText={helperText}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<ExtensionOutlinedIcon fontSize='small' />}
                endIcon={<ArrowOutwardIcon fontSize='small' />}
                onClick={handleExtensionClick}
                variant='outlined'
              >
                Track using chrome extension
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin='normal'
                placeholder='Paste your job description here...'
                value={memoizedPastedText}
                onChange={handlePastedTextInputChange}
                multiline
                rows={8}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display='flex' justifyContent='flex-end'>
                <Button onClick={onClose} variant='outlined' size='large'>Cancel</Button>
                <SpinnerButton
                  onClick={() => {
                    confirm(memoizedUrlValue || memoizedPastedText, source);
                    trackEvents(EEvents.CLICK, {
                      feature: 'HomePage - Application',
                      type: 'Button',
                      label: 'Add Application Confirmation',
                    });
                  }}
                  size='large'
                  variant='contained'
                  isLoading={disableConfirm}
                  disabled={error || (!memoizedUrlValue && !memoizedPastedText)}
                  sx={{ ml: 2 }}
                >
                  Add Application
                </SpinnerButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box flex={1} paddingY={4} paddingX={2} bgcolor='#F8F8F8'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Quick Guide</Typography>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label='styled tabs example'
                variant='scrollable'
                scrollButtons='auto'
                textColor='primary'
                TabIndicatorProps={{ style: { backgroundColor: '#4CAF50', top: 0, height: '2px' } }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center', // This centers the tabs
                  '.MuiTabs-flexContainer': {
                    justifyContent: 'center', // Center the tabs within the container
                    boxSizing: 'border-box',
                  },
                  '.MuiTabs-indicator': {
                    top: 0,
                    left: 0,
                    height: '4px',
                    width: '100%',
                  },
                  '.MuiTab-root': {
                    minHeight: '48px',
                  },
                }}
              >
                <Tab sx={{ borderTop: '2px solid #667085' }} label='Job Description URL' />
                <Tab sx={{ borderTop: '2px solid #667085' }} label='Chrome Extension' />
                <Tab sx={{ borderTop: '2px solid #667085' }} label='Pasted JD' />
              </Tabs>
            </Grid>
            <ApplicationQuickGuide
              isMobile={isMobile}
              stepperString={quickGuideSteps[tabValue]}
              videoLink={quickGuideVideoLinks[tabValue]}
              note={quickGuideNote[tabValue]}
            />
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
}
