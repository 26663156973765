import { EEvents, IIdentifyUserArgs, TEventProperties } from 'instrumentation/analytics.types';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { isDev } from 'utils/environment';


export function init() {
  if (isDev()) { return; }
  initPosthog();
  initMixpanel();
}

export function reset() {
  if (isDev()) { return; }
  posthog.reset();
  mixpanel.reset();
}

export function trackPageView() {
  if (isDev()) { return; }
  posthog.capture('$pageview');
  mixpanel.track_pageview();
}

export function trackEvents(eventName: EEvents, properties?: TEventProperties) {
  if (isDev()) { return; }
  posthog.capture(eventName, properties);
  mixpanel.track(eventName, properties);
}

export function identifyUser({ userId, ...rest }: IIdentifyUserArgs) {
  if (isDev()) { return; }
  posthog.identify(userId, { set: rest?.set }, { setOnce: rest?.setOnce });
  mixpanel.identify(userId);
  mixpanel.people.set_once('$email', rest.email);
  mixpanel.people.set_once('$name', rest.name);
}

function initPosthog() {
  const key = process.env.REACT_APP_POSTHOG_API_KEY || '';
  posthog.init(key, { api_host: 'https://us.i.posthog.com' });
}

function initMixpanel() {
  const key = process.env.REACT_APP_MIXPANEL_API_KEY || '';
  mixpanel.init(key, { debug: true, track_pageview: true, persistence: 'localStorage' });
}
