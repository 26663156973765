import { useCreateSolution } from 'pages/Submissions/queries';


interface ErrorWithMessage {
  message: string;
}

type UseSolutionSubmissionProps = {
  onSuccessCallback?: () => void;
  onErrorCallback?: (error: ErrorWithMessage) => void;
};

const useSolutionSubmission = ({
  onSuccessCallback,
  onErrorCallback,
}: UseSolutionSubmissionProps) => {
  const createSolutionHook = useCreateSolution({
    onSuccess: () => {
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: (error) => {
      if (onErrorCallback) {
        onErrorCallback(error);
      }
    },
  });

  return {
    createSolutionHook,
  };
};

export default useSolutionSubmission;
