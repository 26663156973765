import { Grid } from '@mui/material';
import { IApplication } from 'pages/HomePage/queries';
import { EExperience, EPosition, ERequestStatus } from 'pages/HomePage/types';
import ExampleApplicationCard from 'pages/Authentication/components/ExampleApplicationCard';


export default function ApplicationExample() {
  const dummyApplicationList: IApplication[] = [
    {
      id: '1',
      role: EPosition.POSITION_BACKEND,
      skills: ['C', 'Python', 'Go'],
      company: {
        name: 'Uber',
        logo: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_2/r3brbjf6y7thcnqhchq2',
      },
      job_posted: '2024-08-01T16:58:30+0000',
      job_applied: '2024-08-01T16:58:30+0000',
      job_description_id: '1',
      leetcode_questions: null,
      exp_level: EExperience.EXPERIENCE_ENTRY,
      request_status: ERequestStatus.SUCCESS,
      error_details: null,
    },
    {
      id: '2',
      role: EPosition.POSITION_FRONTEND,
      skills: ['React', 'HTML', 'CSS'],
      company: {
        name: 'Oracle',
        logo: 'https://images.crunchbase.com/image/upload/t_cb-default-original/lftp498venorpfdjmczr',
      },
      job_posted: '2024-06-10T16:58:30+0000',
      job_applied: '2024-06-10T16:58:30+0000',
      leetcode_questions: null,
      job_description_id: '1',
      exp_level: EExperience.EXPERIENCE_ENTRY,
      request_status: ERequestStatus.SUCCESS,
      error_details: null,
    },
    {
      id: '3',
      role: EPosition.POSITION_SOFTWARE_ENGINEER,
      skills: ['SQL', 'Python', 'React'],
      company: {
        name: 'Meta',
        logo: 'https://images.crunchbase.com/image/upload/c_pad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/whm4ed1rrc8skbdi3biv',
      },
      job_posted: '2024-07-11T16:58:30+0000',
      job_applied: '2024-07-11T16:58:30+0000',
      leetcode_questions: null,
      job_description_id: '1',
      exp_level: EExperience.EXPERIENCE_ENTRY,
      request_status: ERequestStatus.SUCCESS,
      error_details: null,
    },
  ];

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center'>
      <Grid item xs={12} sm={6}>
        <ExampleApplicationCard application={dummyApplicationList[0]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ExampleApplicationCard application={dummyApplicationList[1]} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ExampleApplicationCard application={dummyApplicationList[2]} />
      </Grid>
    </Grid>
  );
}
