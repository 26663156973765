import { Grid } from '@mui/material';
import { IQuestion } from 'pages/HomePage/queries';
import React from 'react';
import ExampleApplicationQuestionsCard from 'pages/Authentication/components/ExampleApplicationQuestionsCard';
import { ERequestStatus } from 'pages/HomePage/types';


export default function QuestionExample() {
  const dummyQuestionsList: IQuestion[] = [
    {
      id: '1',
      application: null,
      company: null,
      title: 'URL Shortener',
      resume_points: null,
      description: '',
      skills: ['Distributed systems', 'Fault-tolerant architectures', 'Programming in C++/Java/Python/Go'],
      duration: 45,
      bookmark: false,
      submission: null,
    },
    {
      id: '2',
      application: null,
      company: null,
      title: 'Scalable Cache Layer',
      resume_points: null,
      description: '',
      skills: ['Caching Algorithms', 'Scalability', 'Database Management'],
      duration: 45,
      bookmark: false,
      submission: {
        id: '1',
        created_at: '',
        created_by: '',
        updated_at: '',
        request_status: ERequestStatus.PROCESSING,
        error_details: null,
        question_id: '1',
        question_str: '',
        repo_url: '',
        repo_type: 'GITHUB',
        s3_url: '',
        scores: ['10'],
        overall_score: 67,
        positive_comments: null,
        negative_comments: null,
      },
    },
    {
      id: '3',
      application: null,
      company: null,
      title: 'API Rate Limiter',
      resume_points: null,
      description: '',
      skills: ['API Development', 'Rate Limiting', 'Concurrency'],
      duration: 45,
      bookmark: false,
      submission: {
        id: '1',
        created_at: '',
        created_by: '',
        updated_at: '',
        request_status: ERequestStatus.SUCCESS,
        error_details: null,
        question_id: '1',
        question_str: '',
        repo_url: '',
        repo_type: 'GITHUB',
        s3_url: '',
        scores: ['10'],
        overall_score: 67,
        positive_comments: null,
        negative_comments: null,
      },
    },
  ];

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center'>
      <Grid item xs={12}>
        <ExampleApplicationQuestionsCard questions={dummyQuestionsList} />
      </Grid>
    </Grid>
  );
}
