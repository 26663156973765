import {
  Avatar, FormControl, IconButton, InputLabel, MenuItem, Stack, Typography,
} from '@mui/material';
import { BusinessCenterRounded, DeleteRounded } from '@mui/icons-material';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';


type TProps = {
  logo? : string,
  title: string,
  subHeader: string,
  onButtonClick: () => void,
  type: 'success' | 'error' | 'warning' | 'default',
}

export default function ApplicationDetailsHeader({
  logo, title, subHeader, onButtonClick, type,
}: TProps) {
  const [status, setStatus] = useState<string>('Applied');
  const chipText = () => {
    if (type === 'success') {
      return 'Generated';
    }
    else if (type === 'error') {
      return 'Failed';
    }
    else if (type === 'warning') {
      return 'Processing';
    }
    else {
      return 'No Question Generated';
    }
  };

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} sx={{ alignItems: 'flex-start' }}>
      <Stack direction='row' spacing={2} sx={{ alignItems: 'center', flex: '1 1 auto' }}>
        {logo ? (
          <Avatar src={logo} sx={{ '--Avatar-size': '64px' }}>
            {title[0]}
          </Avatar>
        ) : (
          <Avatar>
            <BusinessCenterRounded />
          </Avatar>
        )}
        <div>
          <Stack direction='row' spacing={2} sx={{ alignItems: 'center', flexWrap: 'wrap' }}>
            <Typography variant='h4'>{title}</Typography>
            <Chip
              icon={<CheckCircleIcon color={type as 'success' | 'error' | 'warning' | 'disabled' | 'action' | 'inherit' | 'primary' | 'secondary' | 'info' | undefined} />}
              label={chipText()}
              size='small'
              variant='outlined'
            />
          </Stack>
          <Typography color='text.secondary' variant='body1'>
            {subHeader}
          </Typography>
        </div>
      </Stack>
      <div>
        <Stack direction='row' spacing={2}>
          <FormControl sx={{ width: '200px' }}>
            <InputLabel id='status-select-label'>Application Status</InputLabel>
            <Select
              labelId='status-select-label'
              label='Application Status'
              onChange={(event: SelectChangeEvent) => setStatus(event.target.value as string)}
              value={status}
            >
              <MenuItem key='applied' value='Applied'>
                <Typography variant='body1'>
                  Applied
                </Typography>
              </MenuItem>
              <MenuItem key='interview' value='Interview'>
                <Typography variant='body1'>
                  Interview
                </Typography>
              </MenuItem>
              <MenuItem key='hired' value='Hired'>
                <Typography variant='body1'>
                  Hired
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <IconButton color='error' onClick={onButtonClick}>
            <DeleteRounded />
          </IconButton>
        </Stack>
      </div>
    </Stack>
  );
}
