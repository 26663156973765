enum EEnvironments {
  CI = 'ci',
  DEVELOPMENT = 'development',
  LOCAL = 'local',
  PRODUCTION = 'production',
  STAGING = 'staging',
  TEST = 'test',
}

function isValidEnvironment(env: string) {
  return Object.values(EEnvironments).includes(env as EEnvironments);
}

function getEnvironment() {
  const env = process.env.REACT_APP_ENV?.toLocaleLowerCase();
  if (!env) { throw new Error('REACT_APP_ENV is not defined'); }
  if (!isValidEnvironment(env)) { throw new Error('REACT_APP_ENV is not a valid environment'); }
  return env;
}

export const isDev = () => {
  const env = getEnvironment();
  return env === EEnvironments.DEVELOPMENT || env === EEnvironments.LOCAL;
};

export const isTest = () => {
  const env = getEnvironment();
  return env === EEnvironments.TEST || env === EEnvironments.CI;
};

export default EEnvironments;
