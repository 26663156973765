import React, { useEffect, useRef } from 'react';
import Spinner from 'components/Common/Spinner';
import { useNavigate } from 'react-router-dom';
import { auth, homepage } from 'utils/spaUrls';
import { Logger } from 'utils/logger';
import authentication from 'pages/Authentication/constants';
import { OauthAuthenticationType, useCreateUser } from 'pages/Authentication/queries';
import { save } from 'utils/cacheUtils';
import { CacheKeys } from 'utils/constants';
import { identifyUser } from 'instrumentation/analytics';
import { OAuthAuthenticateResponse } from '@stytch/vanilla-js';


type TProps = {
  stytchResponse: OAuthAuthenticateResponse;
};

export default function SignUp({ stytchResponse }: TProps) {
  const navigate = useNavigate();
  const createUserHook = useCreateUser();
  const hasCreatedUserRef = useRef(false); // Track if user creation has been attempted

  useEffect(() => {
    const createUser = async () => {
      if (!stytchResponse || !stytchResponse.user || !stytchResponse.user_id) {
        Logger.error('Invalid stytchResponse');
        navigate(auth.logout, { replace: true });
        return;
      }

      if (hasCreatedUserRef.current) {
        return;
      }

      hasCreatedUserRef.current = true;

      try {
        const data = await createUserHook.mutateAsync({
          email: stytchResponse.user.emails[0].email,
          provider: stytchResponse.provider_type === authentication.Google
            ? OauthAuthenticationType.OAUTH_AUTHENTICATION_TYPE_GOOGLE
            : OauthAuthenticationType.OAUTH_AUTHENTICATION_TYPE_LINKEDIN,
          first_name: stytchResponse.user.name.first_name,
          last_name: stytchResponse.user.name.last_name,
          stytch_id: stytchResponse.user_id,
          oauth_id: stytchResponse.user.providers[0].oauth_user_registration_id,
        });

        save(CacheKeys.userId, data.data.id || '');
        identifyUser({
          userId: data.data.id || '',
          email: stytchResponse.user.emails[0].email,
          name: `${stytchResponse.user.name.first_name} ${stytchResponse.user.name.last_name}`,
        });
        navigate(homepage, { replace: true });
      }
      catch (error) {
        Logger.error('Failed to create a user', error);
        navigate(auth.logout, { replace: true });
      }
    };

    createUser();
  }, [stytchResponse, navigate, createUserHook]);

  return <Spinner />;
}
