import React from 'react';
import {
  Alert, Grid, ListItem, Snackbar,
  SnackbarOrigin, IconButton, List, Tooltip, ListItemText, AlertTitle, ListItemButton, ListItemIcon,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { trimText } from 'pages/HomePage/components/utils';
import ContactUsButton from 'components/Common/ContactUsButton';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';


type TProps = {
    errorUrls: (string | null)[],
}

export default function ApplicationErrorPopup({ errorUrls }: TProps) {
  const [isOpen, toggleOpen] = React.useState(true);
  const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' };

  const handleClick = () => {
    toggleOpen(true);
  };

  const handleClose = () => {
    toggleOpen(false);
  };

  const handleExtensionClick = (url : string) => {
    window.open(url, '_blank');
  };

  // Extract and filter URLs
  const extractedUrls = errorUrls
    .filter((url) => url !== null)
    .map((url) => extractUrl(url as string))
    .filter((url) => url !== 'URL not found');

  return (
    <>
      <IconButton
        color='error'
        onClick={handleClick}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'error.main',
          '&:hover': {
            backgroundColor: 'error.dark',
          },
        }}
      >
        <ErrorOutlineIcon sx={{ color: 'white' }} />
      </IconButton>
      <Snackbar
        anchorOrigin={anchorOrigin}
        autoHideDuration={5000}
        sx={{ width: '30%' }}
        onClose={handleClose}
        open={isOpen}
      >
        <Alert onClose={handleClose} severity='error'>
          <AlertTitle>URLs that could not be parsed:</AlertTitle>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <List>
                {extractedUrls.map((url, index) => (
                  <Tooltip key={url} title={url}>
                    <ListItem disableGutters sx={{ pr: '0px' }}>
                      <ListItemText primary={trimText(url, 40)} />
                      <ListItemButton
                        sx={{ p: '0px' }}
                        onClick={() => handleExtensionClick(url)}
                      >
                        <ListItemIcon sx={{ color: 'inherit' }}>
                          <ArrowOutwardIcon fontSize='small' />
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} container justifyContent='center'>
              <ContactUsButton links={extractedUrls} />
            </Grid>
          </Grid>
        </Alert>
      </Snackbar>
    </>
  );
}

export function extractUrl(errorDetails: string): string {
  const urlPattern = /(https?:\/\/[^\s]+)/g;
  const matches = errorDetails.match(urlPattern);
  return matches ? matches[0] : 'URL not found';
}
