import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { ReactNode } from 'react';
import ActionButtonGroup from 'components/Common/ActionButtonGroup';


const steps = ['Application', 'Question', 'Submit Solution'];

type TProps = {
  StepOne: ReactNode,
  StepTwo: ReactNode,
  StepThree: ReactNode,
};

export default function DrawerStepper({
  StepOne, StepTwo, StepThree,
}: TProps) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}><StepLabel>{label}</StepLabel></Step>
        ))}
      </Stepper>
      {/* eslint-disable-next-line no-nested-ternary */}
      {activeStep === 0 ? (
        <>
          {StepOne}
          <Box sx={{ flexDirection: 'column', pt: 2 }}>
            <ActionButtonGroup>
              <Button onClick={handleNext} variant='contained' size='large'>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </ActionButtonGroup>
          </Box>
        </>
      ) : activeStep === 1 ? (
        <>
          {StepTwo}
          <Box sx={{ flexDirection: 'column', pt: 2 }}>
            <ActionButtonGroup>
              <Button
                color='inherit'
                onClick={handleBack}
                variant='outlined'
                size='large'
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button onClick={handleNext} variant='contained' size='large'>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </ActionButtonGroup>
          </Box>
        </>
      ) : (
        <>
          {StepThree}
          <Box sx={{ flexDirection: 'column', pt: 2 }}>
            <ActionButtonGroup>
              <Button
                color='inherit'
                disabled={activeStep === 0}
                onClick={handleBack}
                variant='outlined'
                size='large'
                sx={{ mr: 1 }}
              >
                Back
              </Button>
            </ActionButtonGroup>
          </Box>
        </>
      )}
    </Box>
  );
}
