import { Grid } from '@mui/material';
import React from 'react';
import ProfileCard from 'pages/Coaching/components/ProfileCard';
import profiles from 'pages/Coaching/profile.json';
import { ProfilePayload, useAddInterestedUser } from 'pages/HomePage/queries';
import SignUpBanner from 'pages/Coaching/components/SignUpBanner';
import calendlyLink from 'pages/Coaching/constants';


export default function Coaching() {
  const displayProfiles = profiles as ProfilePayload;
  const interestedUserHook = useAddInterestedUser();
  const handleClick = () => {
    interestedUserHook.mutate({});
    window.open(calendlyLink, '_blank');
  };

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center'>
      <SignUpBanner handleClick={handleClick} />
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {displayProfiles.profiles.map((profile) => (
            <Grid item xs={12} sm={6} md={3} key={profile.name}>
              <ProfileCard
                handleClick={handleClick}
                linkedInUrl={profile.linkedInUrl}
                experience={profile.experience}
                image={profile.image}
                name={profile.name}
                position={profile.position}
                skills={profile.skills}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
