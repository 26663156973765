import { experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { Theme } from '@mui/material';
import {
  california, chateauGreen, kepple, nevada, redOrange, shakespeare, stormGrey,
} from 'styles/theme/colors';
import shadows from 'styles/theme/shadows';
import components from 'styles/theme/components/components';


export default function createTheme(): Theme {
  const theme = extendTheme({
    custom: {
      appBarHeight: '64px',
      sidebarWidth: '210px',
      smSidebarWidth: '80px',
      mobileSideBarWidth: '80%',
    },
    colorSchemes: {
      light: {
        palette: {
          action: { disabledBackground: 'rgba(0, 0, 0, 0.06)' },
          background: {
            default: 'var(--mui-palette-common-white)',
            paper: 'var(--mui-palette-common-white)',
          },
          common: { black: '#000000', white: '#ffffff' },
          divider: stormGrey[200],
          error: {
            ...redOrange,
            light: redOrange[400],
            main: redOrange[500],
            dark: redOrange[600],
            contrastText: 'var(--mui-palette-common-white)',
          },
          info: {
            ...shakespeare,
            light: shakespeare[400],
            main: shakespeare[500],
            dark: shakespeare[600],
            contrastText: 'var(--mui-palette-common-white)',
          },
          primary: {
            ...chateauGreen,
            light: chateauGreen[400],
            main: chateauGreen[500],
            dark: chateauGreen[600],
            contrastText: 'var(--mui-palette-common-white)',
          },
          secondary: {
            ...nevada,
            light: nevada[600],
            main: nevada[700],
            dark: nevada[800],
            contrastText: 'var(--mui-palette-common-white)',
          },
          success: {
            ...kepple,
            light: kepple[400],
            main: kepple[500],
            dark: kepple[600],
            contrastText: 'var(--mui-palette-common-white)',
          },
          text: {
            primary: '#212636',
            secondary: '#667085',
            disabled: '#8a94a6',
          },
          warning: {
            ...california,
            light: california[400],
            main: california[500],
            dark: california[600],
            contrastText: 'var(--mui-palette-common-white)',
          },
        },
      },
    },
    components,
    shadows: shadows.light,
    shape: { borderRadius: 8 },
    typography: {
      fontFamily:
              '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      body1: { fontSize: '1rem', fontWeight: 400, lineHeight: 1.5 },
      body2: { fontSize: '0.875rem', fontWeight: 400, lineHeight: 1.57 },
      button: { fontWeight: 500 },
      caption: { fontSize: '0.75rem', fontWeight: 400, lineHeight: 1.66 },
      subtitle1: { fontSize: '1rem', fontWeight: 500, lineHeight: 1.57 },
      subtitle2: { fontSize: '0.875rem', fontWeight: 500, lineHeight: 1.57 },
      overline: {
        fontSize: '0.75rem',
        fontWeight: 500,
        letterSpacing: '0.5px',
        lineHeight: 2.5,
        textTransform: 'uppercase',
      },
      h1: { fontSize: '3.5rem', fontWeight: 500, lineHeight: 1.2 },
      h2: { fontSize: '3rem', fontWeight: 500, lineHeight: 1.2 },
      h3: { fontSize: '2.25rem', fontWeight: 500, lineHeight: 1.2 },
      h4: { fontSize: '2rem', fontWeight: 500, lineHeight: 1.2 },
      h5: { fontSize: '1.5rem', fontWeight: 500, lineHeight: 1.2 },
      h6: { fontSize: '1.125rem', fontWeight: 500, lineHeight: 1.2 },
    },
  });

  return theme;
}
