import http from 'utils/http';
import { submissions } from 'utils/apiUrls';
import {
  QueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  QueryObserverOptions,
} from '@tanstack/react-query';
import { ERequestStatus } from 'pages/HomePage/types';
import { TApiListResponse } from 'utils/apiResponse.type';


export interface ISolution {
  id: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  request_status: ERequestStatus;
  error_details: string | null;
  question_id: string;
  question_str: string;
  repo_url: string;
  repo_type: string | 'GITHUB';
  s3_url: string;
  scores: string[];
  overall_score: number;
  positive_comments: string | null;
  negative_comments: string | null;
}

export interface ICreateSolutionRequest {
  questionId: string;
  git_url: string;
  url_type: string;
}

export const userSolutionQueryKey = (questionId: string) => [
  'users',
  'solutions',
  questionId,
  'list',
];

export function useGetUserSolution(
  questionId: string,
  options?: QueryObserverOptions<TApiListResponse<ISolution>, Error>,
) {
  return useQuery<TApiListResponse<ISolution>, Error>({
    queryKey: userSolutionQueryKey(questionId),
    queryFn: () => http.get(submissions.latest(questionId)),
    refetchInterval: 2000,
    ...options,
  });
}

export const userSolutionListQueryKey = () => ['users', 'solutions', 'list'];

export function useGetUserSolutionsList(
  options?: QueryObserverOptions<TApiListResponse<ISolution>, Error>,
) {
  return useQuery<TApiListResponse<ISolution>, Error>({
    queryKey: userSolutionListQueryKey(),
    queryFn: () => http.get(submissions.list),
    refetchInterval: 2000,
    ...options,
  });
}


export function useCreateSolution(
  options?: UseMutationOptions<void, Error, ICreateSolutionRequest>,
) {
  return useMutation<void, Error, ICreateSolutionRequest>({
    mutationFn: (app) => {
      const { questionId, ...payload } = app;
      return http.post(submissions.create(questionId), payload);
    },
    ...options,
  });
}

export function useDeleteSolution(options?: UseMutationOptions<void, Error, string>) {
  return useMutation<void, Error, string>({
    mutationFn: (solutionId) => http.delete(submissions.delete(solutionId)),
    ...options,
  });
}

export default function useCheckRepoVisibility(
  repoUrl: string,
  options?: QueryOptions<string, Error>,
) {
  return useQuery<string, Error>({
    queryKey: ['repoVisibility', repoUrl],
    queryFn: () => http.checkRepoVisibility(repoUrl),
    staleTime: 0,
    ...options,
  });
}
