import {
  Grid, TextField, Button, FormControl,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import useCheckRepoVisibility from 'pages/Submissions/queries';
import { GithubVisbility } from 'utils/constants';


type TProps = {
  onRepoSubmit: (url: string) => void;
  disabled?:boolean;
};

export default function RepoSubmission({ onRepoSubmit, disabled }: TProps) {
  const [repoUrl, setRepoUrl] = useState('');
  const [isError, setIsError] = useState<boolean>(false);
  const [shouldCheckVisibility, setShouldCheckVisibility] = useState<boolean>(false);

  const [helperText, setHelperText] = useState<string | undefined>();
  const { data: repoVisibility } = useCheckRepoVisibility(repoUrl);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepoUrl(event.target.value);
    setHelperText('');
    setIsError(false);
  };

  const handleSubmit = () => {
    // TODO: Make a Backend request for this verification
    // setShouldCheckVisibility(true);
    onRepoSubmit(repoUrl);
  };

  useEffect(() => {
    if (shouldCheckVisibility) {
      if (repoVisibility === GithubVisbility.public) {
        setHelperText(undefined);
        setIsError(false);
        onRepoSubmit(repoUrl);
      }
      else if (repoVisibility !== undefined) {
        setHelperText(
          'Invalid or private GitHub repository URL. Please ensure the URL is correct and points to a valid repository.',
        );
        setIsError(true);
      }
      setShouldCheckVisibility(false);
    }
  }, [repoVisibility, repoUrl, shouldCheckVisibility, onRepoSubmit]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField
            disabled={disabled}
            label='Public Repository Source URL'
            helperText={helperText || 'Example: https://github.com/username/public-repo'}
            variant='outlined'
            value={repoUrl}
            onChange={handleInputChange}
            fullWidth
            margin='normal'
            error={isError}
          />
          <Button
            onClick={handleSubmit}
            size='large'
            variant='contained'
            disabled={disabled}
          >
            Submit
          </Button>
        </FormControl>
      </Grid>
    </Grid>
  );
}
