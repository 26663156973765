import type { Components } from '@mui/material/styles';
import { Theme } from '@mui/material';


const MuiTab = {
  defaultProps: { disableRipple: true },
  styleOverrides: {
    root: {
      textTransform: 'none',
      '&:hover': { color: 'var(--mui-palette-text-primary)' },
      '&:focus-visible': { outline: '2px solid var(--mui-palette-primary-main)' },
    },
  },
} satisfies Components<Theme>['MuiTab'];

export default MuiTab;
