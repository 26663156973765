import React from 'react';
import {
  Grid, Box, Typography,
} from '@mui/material';
import EmptyStateSvg from 'assets/EmptyState.svg';


export default function EmptyFilteredApplications() {
  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center' direction='column'>
      <Grid item xs={12}>
        <Box
          component='img'
          src={EmptyStateSvg}
          alt='No Applications'
          sx={{ height: '148px', width: '148px', marginBottom: '16px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ maxWidth: '605px', textAlign: 'center', margin: '0 auto' }}>
          <Typography variant='h5' gutterBottom>
            No Filtered Applications
          </Typography>
          <Typography variant='body1' color='textSecondary'>
            We don&apos;t see any applications for the current filters.
            Please track applications with the current
            filters or change the filters accordingly.
          </Typography>

        </Box>
      </Grid>
    </Grid>
  );
}
