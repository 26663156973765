import { Grid, Stack, Typography } from '@mui/material';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  useCreateQuestions,
  useDeleteApplication, useGetApplicationQuestions,
  useGetUserApplication,
  userApplicationListQueryKey,
  userApplicationQueryKey, userApplicationQuestionsQueryKey,
} from 'pages/HomePage/queries';
import Spinner from 'components/Common/Spinner';
import React, { useState } from 'react';
import { experienceParsed, positionParsed } from 'pages/HomePage/constants';
import CustomChip from 'pages/HomePage/components/CustomChip';
import stringToDate from 'components/Common/Date';
import ApplicationDetailsHeader from 'pages/HomePage/components/ApplicationDetailsHeader';
import { useQueryClient } from '@tanstack/react-query';
import { homepage } from 'utils/spaUrls';
import ApplicationInfoCard from 'pages/HomePage/components/ApplicationInfoCard';
import ApplicationQuestionsCard from 'pages/HomePage/components/ApplicationQuestionsCard';
import AppQuestionsLoadingCard from 'pages/HomePage/components/AppQuestionsLoadingCard';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';
import theme from 'theme';
import LeetCodePractice from 'pages/HomePage/components/LeetCodePractice';


export default function ApplicationDetails() {
  const { applicationId } = useParams<{ applicationId: string }>();
  const deleteApplicationHook = useDeleteApplication();
  const createQuestionsHook = useCreateQuestions();
  const [questionProcessing, setQuestionProcessing] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    data: questionData,
  } = useGetApplicationQuestions(applicationId || '', {
    queryKey: userApplicationQuestionsQueryKey(applicationId || ''),
    enabled: Boolean(applicationId),
  });
  const {
    data: applicationData,
    isLoading,
  } = useGetUserApplication(applicationId || '', {
    queryKey: userApplicationQueryKey(applicationId || ''),
    enabled: Boolean(applicationId),
  });

  if (!applicationData || isLoading || !applicationData?.data) {
    return <Spinner />;
  }

  const validCompanyUrl = Boolean(applicationData.data[0]?.company?.url && applicationData.data[0].company.url.includes('https'));

  const companyUrlValue = validCompanyUrl ? (
    <Link component={NavLink} to={applicationData.data[0].company.url || 'none'} target='_blank'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <OpenInNewIcon fontSize='small' />
        <Typography>{applicationData.data[0].company.name}</Typography>
      </Stack>
    </Link>
  ) : 'None';

  const companyDetails = [
    { key: 'Description', value: applicationData.data[0].company.about },
    { key: 'Company', value: applicationData.data[0].company.name },
    {
      key: 'Company Url',
      value: companyUrlValue,
    },
    { key: 'Industry', value: applicationData.data[0].company.industry },
    { key: 'Size', value: applicationData.data[0].company.size },
  ].filter((item) => item.value !== undefined && item.value !== null && item.value.toString().toLowerCase() !== 'none');

  const validJobUrl = Boolean(applicationData.data[0]?.jd_url && applicationData.data[0].jd_url.includes('https'));

  const jobUrlValue = validJobUrl ? (
    <Link component={NavLink} to={applicationData.data[0].jd_url || ''} target='_blank'>
      <Stack direction='row' spacing={1} alignItems='center'>
        <OpenInNewIcon fontSize='small' style={{ color: theme.palette.text.secondary }} />
        <Typography>External Link</Typography>
      </Stack>
    </Link>
  ) : 'None';

  const applicationDetails = [
    { key: 'Job Applied', value: stringToDate({ dateString: applicationData.data[0].job_applied, formatStr: 'MMMM d, yyyy' }) },
    { key: 'Location', value: applicationData.data[0].location },
    { key: 'Salary', value: applicationData.data[0].salary },
    { key: 'Experience Level', value: experienceParsed.parse(applicationData.data[0].exp_level).title },
    { key: 'Skills', value: <CustomChip skills={applicationData.data[0].skills} renderNumber={3} /> },
    {
      key: 'Job Url',
      value: jobUrlValue,
    },
  ].filter((item) => item.value !== undefined && item.value !== null && item.value.toString().toLowerCase() !== 'none');

  const handleDelete = () => {
    trackEvents(EEvents.CLICK, {
      feature: 'ApplicationDetails - Application',
      type: 'Button',
      label: 'Delete Application',
    });
    deleteApplicationHook.mutate(
      applicationData.data[0].id,
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: userApplicationListQueryKey() });
          navigate(homepage);
        },
      },
    );
  };

  const chipType = () => {
    if (questionData && questionData?.data?.length > 0) {
      return 'success';
    }
    else if (questionProcessing) {
      return 'warning';
    }
    else if (questionData && questionData?.error !== null) {
      return 'error';
    }
    else {
      return 'default';
    }
  };

  const handleQuestionClick = () => {
    if (applicationId) {
      trackEvents(EEvents.CLICK, {
        feature: 'ApplicationDetails - Question',
        type: 'Button',
        label: 'Generate Questions',
      });
      createQuestionsHook.mutate({
        application_id: applicationId,
      });
      setQuestionProcessing(true);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ApplicationDetailsHeader
          type={chipType()}
          subHeader={positionParsed.parse(applicationData.data[0].role).title}
          title={applicationData.data[0].company.name}
          logo={applicationData.data[0].company.logo}
          onButtonClick={handleDelete}
        />
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid container item xs={12} md={8} spacing={2}>
          {questionData && questionData.data.length > 0 ? (
            <Grid item xs={12}>
              <ApplicationQuestionsCard questions={questionData.data} />
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <AppQuestionsLoadingCard
                  onClick={handleQuestionClick}
                  questionProcessing={questionProcessing}
                />
              </Grid>
              {applicationData.data[0] && applicationData.data[0].leetcode_questions !== null && (
              <Grid item xs={12}>
                <LeetCodePractice questions={applicationData.data[0].leetcode_questions} />
              </Grid>
              )}
            </>
          )}
        </Grid>
        <Grid container item xs={12} md={4} spacing={2}>
          <Grid item xs={12}>
            <ApplicationInfoCard title='Application Details' details={applicationDetails} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        {questionData && questionData.data.length > 0
            && applicationData.data[0].leetcode_questions ? (
              <>
                <Grid item xs={3}>
                  <LeetCodePractice questions={applicationData.data[0].leetcode_questions} />
                </Grid>
                <Grid item xs={9}>
                  <ApplicationInfoCard title='Company Details' details={companyDetails} />
                </Grid>
              </>
          ) : (
            <Grid item xs={12}>
              <ApplicationInfoCard title='Company Details' details={companyDetails} />
            </Grid>
          )}
      </Grid>
    </Grid>
  );
}
