import React, { useState } from 'react';
import {
  IconButton, Avatar, Menu, MenuItem, ListItemIcon, Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { UserMenuTree } from 'utils/NavTree';
import { useStytchUser } from '@stytch/react';


export default function UserSettingsPopover() {
  const { user } = useStytchUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ p: 0 }} onClick={handleClick}>
        <Avatar
          alt={`${user?.name.first_name} ${user?.name.last_name}`}
          imgProps={{ referrerPolicy: 'no-referrer' }}
          src={user?.providers[0].profile_picture_url}
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {UserMenuTree.map((item) => {
          const { Icon } = item;
          return (
            <MenuItem key={item.name}>
              <Button component={RouterLink} to={item.spaUrl} style={{ textDecoration: 'none' }}>
                <ListItemIcon><Icon fontSize='small' /></ListItemIcon>
                {item.name}
              </Button>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
