import { format, parse, parseISO } from 'date-fns';
import { dateFormats } from 'pages/HomePage/constants';


export default function stringToDate(
  { dateString, formatStr }: { dateString: string, formatStr: string },
) {
  let parsedDate;

  // First, try parsing using parseISO if it's a potential ISO format
  try {
    parsedDate = parseISO(dateString);
    if (!isNaN(parsedDate.getTime())) {
      return format(parsedDate, formatStr);
    }
  }
  catch (error) {
    // Ignore errors and move on to the next step
  }

  // If parseISO fails, try the other formats
  for (const dateFormat of dateFormats) {
    parsedDate = parse(dateString, dateFormat, new Date());
    // Check if the parsed date is valid
    if (!isNaN(parsedDate.getTime())) {
      break;
    }
  }

  // If no valid date was found, return an error or handle it accordingly
  if (!parsedDate || isNaN(parsedDate.getTime())) {
    return null;
  }

  return format(parsedDate, formatStr);
}
