import {
  Card,
  CardContent,
} from '@mui/material';
import React from 'react';
import EmptyQuestions from 'pages/HomePage/components/EmptyQuestions';


type TProps = {
  questionProcessing: boolean;
  onClick: () => void;
}

export default function AppQuestionsLoadingCard({ questionProcessing, onClick }: TProps) {
  return (
    <Card>
      <CardContent sx={{ pb: '8px', marginTop: '25px' }}>
        <EmptyQuestions handleClick={onClick} isLoading={questionProcessing} />
      </CardContent>
    </Card>
  );
}
