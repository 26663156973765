import Box from '@mui/material/Box';
import React from 'react';


export default function BrandingBorder({ children }: { children: React.ReactNode }) {
  return (
    <Box
      component='main'
      sx={{
        backgroundColor: '#FFFFFF',
        minHeight: '450px',
      }}
    >
      {children}
    </Box>
  );
}
