import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import BackgroundSvg from 'assets/tech-skills.svg';


type TProps = {
    handleClick: () => void;
}

export default function SignUpBanner({ handleClick }: TProps) {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          height: { xs: '250px', sm: '300px', md: '350px' },
          backgroundImage: `url(${BackgroundSvg})`,
          backgroundSize: 'contain, contain',
          backgroundPosition: 'left, center',
          backgroundRepeat: 'no-repeat, no-repeat',
          borderRadius: '8px',
          padding: '16px',
          color: 'white',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
          }}
        >
          <Typography variant='h2'>
            Sign Up for Codei Pro
          </Typography>
          <Typography variant='h6' sx={{ marginTop: '8px' }}>
            Get Coached by industry veterans
          </Typography>
          <Button
            variant='contained'
            sx={{
              marginTop: '16px', padding: '12px 24px',
            }}
            onClick={handleClick}
          >
            Schedule a call with us
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}
