import { useState, useCallback, useRef } from 'react';


export default function usePopover<T extends HTMLElement>() {
  const [anchorEl, setAnchorEl] = useState<T | null>(null);
  const open = Boolean(anchorEl);
  const handleOpen = useCallback((event: React.MouseEvent<T>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const anchorRef = useRef<T>(null);

  return {
    anchorEl,
    anchorRef,
    handleOpen,
    handleClose,
    open,
  };
}
