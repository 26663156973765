import {
  Card, CardContent, CardHeader, Grid, Typography,
} from '@mui/material';
import { IQuestion } from 'pages/HomePage/queries';
import React from 'react';
import ExampleQuestionItemCard from 'pages/Authentication/components/ExampleQuestionItemCard';


type TProps = {
    questions: IQuestion[]
}

export default function ExampleApplicationQuestionsCard({ questions }: TProps) {
  return (
    <Card>
      <CardHeader
        sx={{ pb: '0px' }}
        title={<Typography variant='h5'>Questions</Typography>}
      />
      <CardContent sx={{ mt: '-8px' }}>
        <Grid container spacing={2}>
          {questions.map((question) => (
            <Grid item xs={12}>
              <ExampleQuestionItemCard key={question.id} question={question} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
