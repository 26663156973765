import Drawer from '@mui/material/Drawer';
import {
  IconButton, Box, Grid,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from 'theme';
import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';


type TProps = {
    open: boolean,
    handleClose: () => void,
    children: ReactNode,
    title: string,
}

export default function CommonDrawer({
  open, handleClose, children, title,
}: TProps) {
  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: '939px',
          padding: '20px 30px',
          height: `calc(100% + ${theme.custom.appBarHeight})`,
          zIndex: 1200,
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        },
      }}
    >
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: '18px',
        }}
        size='small'
      >
        <CloseIcon fontSize='small' />
      </IconButton>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
          width: '100%',
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant='h4'>{title}</Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: '50px' }}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
