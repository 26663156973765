import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from 'utils/spaUrls';
import { useStytch } from '@stytch/react';
import { Logger } from 'utils/logger';
import { ErrorCodes } from 'utils/errorUtils';
import authentication from 'pages/Authentication/constants';
import { housekeepingOnAuth } from 'pages/Authentication/utils';
import { retrieve } from 'utils/cacheUtils';
import { CacheKeys } from 'utils/constants';
import SignUp from 'pages/Authentication/components/SignUp';
import LoginRedirect from 'pages/Authentication/components/LoginRedirect';


export default function LandingPad() {
  const stytch = useStytch();
  const navigate = useNavigate();
  const hasAuthenticatedRef = useRef(false); // Track if authentication has been attempted

  const params = new URLSearchParams(window.location.search);
  const tokenType = params.get(authentication.StytchTokenType);
  const sessionToken = params.get(authentication.StytchToken);

  const stytchResponse = retrieve(CacheKeys.response, { parseJson: true });

  useEffect(() => {
    const authenticateStytchUser = async () => {
      if (!sessionToken || stytchResponse || tokenType !== authentication.Oauth) return;

      if (hasAuthenticatedRef.current) {
        return;
      }

      hasAuthenticatedRef.current = true;

      try {
        const response = await stytch.oauth.authenticate(sessionToken, {
          session_duration_minutes: authentication.SessionDurationMinutes,
        });
        housekeepingOnAuth(response);
      }
      catch (error) {
        if (error instanceof Error) {
          Logger.error(ErrorCodes.auth.stytchGetToken, error.message);
          navigate(auth.logout, { replace: true });
        }
        else {
          Logger.error(ErrorCodes.auth.stytchClientMisc, error);
          navigate(auth.logout, { replace: true });
        }
      }
    };

    authenticateStytchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionToken, tokenType, navigate, stytch]);

  const trustedMetadata = stytchResponse?.user?.trusted_metadata;
  const userCodeiId = trustedMetadata?.codei_id as string;

  if (!userCodeiId && trustedMetadata) {
    return <SignUp stytchResponse={stytchResponse} />;
  }

  return <LoginRedirect stytchResponse={stytchResponse} />;
}
