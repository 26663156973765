import React from 'react';
import { ErrorBoundary, Provider } from '@rollbar/react';
import Rollbar from 'rollbar';


type TProps = {
   children: React.ReactNode;
}

export default function RollbarProviderWithHistory({ children }: TProps) {
  const rollbarConfig: Rollbar.Configuration = {
    accessToken: process.env.REACT_APP_ROLLBAR_API_KEY,
    environment: process.env.REACT_APP_ENV,
    codeVersion: process.env.REACT_APP_VERSION,
    host: window.location.host,
    captureUncaught: true,
    captureUnhandledRejections: true,
  };

  const rollbarInstance = Rollbar.init(rollbarConfig);

  return (
    <Provider instance={rollbarInstance}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Provider>
  );
}
