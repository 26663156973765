import { User } from '@stytch/vanilla-js';


type UserDataSent = {
    sessionId: string,
    token: string,
    email: string,
    user: User,
}

export default function authenticateExtension(userData:UserDataSent) {
  window.postMessage({
    action: 'signedin',
    data: { userData },
  });
}
