import * as React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import type { Components } from '@mui/material/styles';
import { Theme } from '@mui/material';


const MuiChip = {
  defaultProps: {
    color: 'secondary', // default will be removed in material v6
    deleteIcon: <DeleteOutlineOutlinedIcon />,
  },
  styleOverrides: {
    root: { borderRadius: '8px', fontWeight: 500 },
    outlinedSecondary: () => ({ borderColor: 'var(--mui-palette-secondary-200)', color: 'var(--mui-palette-secondary-900)' }),
    iconSmall: { fontSize: 'var(--icon-fontSize-sm)' },
    iconMedium: { fontSize: 'var(--icon-fontSize-md)' },
  },
} satisfies Components<Theme>['MuiChip'];

export default MuiChip;
