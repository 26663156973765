import React, { useContext } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SideNavCtx } from 'components/Layouts/Borders';
import { NavTree, TNavItemModel, UserMenuTree } from 'utils/NavTree';
import { feedback, homepage } from 'utils/spaUrls';
import logo from 'assets/logo.png';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import theme from 'theme';


export default function SideNav() {
  const navigate = useNavigate();
  const {
    drawerWidth, isMobile, mobileOpen, setMobileOpen,
  } = useContext(SideNavCtx);

  const handleDrawerToggle = () => {
    if (setMobileOpen) setMobileOpen(!mobileOpen);
  };

  return (
    <Drawer
      anchor='left'
      open={isMobile ? mobileOpen : true}
      onClose={handleDrawerToggle}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          zIndex: isMobile ? 1300 : 1202,
          width: drawerWidth,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: '#1E2822',
          padding: '24px 16px',
        },
      }}
      variant={isMobile ? 'temporary' : 'permanent'}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} justifyContent='space-between' direction='column'>
            <Grid item xs={12}>
              <Toolbar disableGutters>
                <Grid container alignItems='center'>
                  <Grid item xs>
                    <Link
                      component={NavLink}
                      to={homepage}
                      onClick={() => navigate(homepage)}
                    >
                      <img
                        src={logo}
                        alt='Logo'
                        style={{
                          borderRadius: '8px',
                          maxHeight: '55px',
                          minWidth: '138px',
                          minHeight: '55px',
                        }}
                      />
                    </Link>
                  </Grid>
                </Grid>
              </Toolbar>
              <List sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mt: '30px',
              }}
              >
                {NavTree.map((navItem) => <NavItemButton key={navItem.spaUrl} navItem={navItem} />)}
              </List>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <List sx={{
            display: 'flex', flexDirection: 'column', mt: '30px',
          }}
          >
            {UserMenuTree.map((navItem) => (
              <NavItemButton key={navItem.spaUrl} navItem={navItem} />
            ))}
          </List>
        </Box>
      </Box>
    </Drawer>
  );
}

function NavItemButton({ navItem }: {navItem: TNavItemModel}) {
  const location = useLocation();
  const { Icon } = navItem;
  const isActive = location.pathname === navItem.spaUrl;
  return (
    <Tooltip title={navItem.name} key={navItem.name} placement='right'>
      <ListItemButton
        component='a'
        href={navItem.spaUrl}
        target={navItem.spaUrl === feedback ? '_blank' : '_self'}
        sx={{
          justifyContent: 'flex-start',
          width: '100%',
          marginBottom: '10px',
          color: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          padding: '8px 16px',
          borderRadius: '8px',
          backgroundColor: isActive ? theme.palette.primary.main : 'inherit',
          '&:hover': {
            backgroundColor: '#343C36',
          },
        }}
      >
        <ListItemIcon sx={{ color: isActive ? '#FFFFFF' : 'text.disabled' }}>
          <Icon />
        </ListItemIcon>
        <Typography variant='body2' color='#FFFFFF'>
          {navItem.name}
        </Typography>
      </ListItemButton>
    </Tooltip>
  );
}
