import { Card, CardContent, Grid } from '@mui/material';
import SquareLoader from 'components/Common/SquareLoader';
import Typography from '@mui/material/Typography';
import React from 'react';
import { CreationType, ERequestStatus } from 'pages/HomePage/types';


type TProps = {
  status: string;
  type: CreationType;
};

export default function LoadingCard({ status, type }: TProps) {
  const saved = type === CreationType.SUB ? 'Creating Evaluation Score' : 'Parsing Job Description';
  const success = type === CreationType.SUB ? 'Preparing your Submission' : 'Preparing your Application';

  const text = status === ERequestStatus.SAVED ? saved : success;
  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <Card
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CardContent>
          <Grid
            container
            spacing={2}
            justifyContent='center'
            alignItems='center'
            direction='column'
          >
            <Grid item alignContent='center' xs={12}>
              <SquareLoader />
            </Grid>
            <Grid item xs={12}>
              <Typography align='center'>{text}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
