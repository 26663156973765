import * as React from 'react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { questions } from 'utils/spaUrls';
import { FilterButton, FilterPopover, useFilterContext } from 'pages/Questions/components/FilterButton';
import { useNavigate } from 'react-router-dom';

// The tabs should be generated using API data.


export interface Filters {
    status?: string;
    company?: string;
    skill?: string;
}

export type SortDir = 'asc' | 'desc';

export interface CustomersFiltersProps {
    filters?: Filters;
    sortDir?: SortDir;
    filterTabs: readonly {
        readonly label: string,
        readonly value: string,
        readonly count: number
    }[],
    }

export function CustomersFilters({ filters = {}, sortDir = 'desc', filterTabs }: CustomersFiltersProps): React.JSX.Element {
  const { status, company, skill } = filters;
  const navigate = useNavigate();

  const updateSearchParams = React.useCallback(
    (newFilters: Filters, newSortDir: SortDir): void => {
      const searchParams = new URLSearchParams();

      if (newSortDir === 'asc') {
        searchParams.set('sortDir', newSortDir);
      }

      if (newFilters.status) {
        searchParams.set('status', newFilters.status);
      }

      if (newFilters.company) {
        searchParams.set('company', newFilters.company);
      }

      if (newFilters.skill) {
        searchParams.set('skill', newFilters.skill);
      }

      navigate(`${questions.list}?${searchParams.toString()}`);
    },
    [navigate],
  );

  const handleClearFilters = React.useCallback(() => {
    updateSearchParams({}, sortDir);
  }, [updateSearchParams, sortDir]);

  const handleStatusChange = React.useCallback(
    (_: React.SyntheticEvent, value: string) => {
      updateSearchParams({ ...filters, status: value }, sortDir);
    },
    [updateSearchParams, filters, sortDir],
  );

  const handleCompanyChange = React.useCallback(
    (value?: string) => {
      updateSearchParams({ ...filters, company: value }, sortDir);
    },
    [updateSearchParams, filters, sortDir],
  );

  const handleSkillChange = React.useCallback(
    (value?: string) => {
      updateSearchParams({ ...filters, skill: value }, sortDir);
    },
    [updateSearchParams, filters, sortDir],
  );

  const hasFilters = status || company || skill;

  return (
    <div>
      <Tabs onChange={handleStatusChange} sx={{ px: 3, textTransform: 'none' }} value={status ?? ''} variant='scrollable'>
        {filterTabs.map((tab) => (
          <Tab
            icon={<Chip label={tab.count} size='small' variant='filled' color='default' />}
            iconPosition='end'
            key={tab.value}
            label={tab.label}
            sx={{ minHeight: 'auto' }}
            tabIndex={0}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Stack
        direction='row'
        spacing={2}
        sx={{
          alignItems: 'center', flexWrap: 'wrap', px: 3, py: 2,
        }}
      >
        <Stack direction='row' spacing={2} sx={{ alignItems: 'center', flex: '1 1 auto', flexWrap: 'wrap' }}>
          <FilterButton
            displayValue={company}
            label='Company'
            onFilterApply={(value) => {
              handleCompanyChange(value as string);
            }}
            onFilterDelete={() => {
              handleCompanyChange();
            }}
            popover={<CompanyFilterPopover title='Filter by Company' />}
            value={company}
          />
          <FilterButton
            displayValue={skill}
            label='Skill'
            onFilterApply={(value) => {
              handleSkillChange(value as string);
            }}
            onFilterDelete={() => {
              handleSkillChange();
            }}
            popover={<CompanyFilterPopover title='Filter by Skill' />}
            value={skill}
          />
          {hasFilters ? <Button onClick={handleClearFilters}>Clear filters</Button> : null}
        </Stack>
      </Stack>
    </div>
  );
}


function CompanyFilterPopover({ title }: {title: string}): React.JSX.Element {
  const {
    anchorEl, onApply, onClose, open, value: initialValue,
  } = useFilterContext();
  const [value, setValue] = React.useState<string>('');

  React.useEffect(() => {
    setValue((initialValue as string | undefined) ?? '');
  }, [initialValue]);

  return (
    <FilterPopover anchorEl={anchorEl} onClose={onClose} open={open} title={title}>
      <FormControl>
        <OutlinedInput
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              onApply(value);
            }
          }}
          value={value}
        />
      </FormControl>
      <Button
        onClick={() => {
          onApply(value);
        }}
        variant='contained'
      >
        Apply
      </Button>
    </FilterPopover>
  );
}
