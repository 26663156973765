import { CacheKeysModel } from 'utils/constants.types';


// eslint-disable-next-line import/prefer-default-export
export const CacheKeys: CacheKeysModel = {
  email: 'email',
  orgId: 'org-id',
  profileSrc: 'profile-src',
  response: 'response',
  sessionId: 'session-id',
  sessionToken: 'session-token',
  token: 'token',
  userId: 'user-id',
};

export const GithubVisbility = {
  public: 'public',
  private: 'private',
  invalid: 'Invalid github repository url',
};

export const SubmitMessage = {
  success: 'Submission Successful!',
  error: (err: string) => `Submission Failed: ${err}`,
};
