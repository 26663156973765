import React from 'react';
import {
  Card, CardContent, CardActions, Button, Typography, Box, Tooltip, Chip, CardMedia,
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';


type TProps = {
    image: string;
    name: string;
    position: string;
    experience: string;
    skills: string[];
    linkedInUrl: string;
    handleClick: () => void;
}

export default function ProfileCard({
  image, name, position, experience, skills, linkedInUrl, handleClick,
}: TProps) {
  const displayedSkills = skills.slice(0, 2);
  const remainingSkills = skills.length > 2 ? skills.slice(2) : [];

  return (
    <Card
      style={{
        borderRadius: '16px', padding: '16px', textAlign: 'center', maxWidth: 300,
      }}
      elevation={14}
    >
      <CardMedia
        sx={{ height: 150 }}
        image={image}
        title='Profile Picture'
      />
      <CardContent>
        <Typography variant='h6' component='div'>
          {name}
          <Link to={linkedInUrl} target='_blank'>
            <LinkedInIcon style={{ marginLeft: '8px', verticalAlign: 'middle', cursor: 'pointer' }} />
          </Link>
        </Typography>
        <Box style={{
          display: 'flex', justifyContent: 'center', gap: '8px', alignItems: 'center', marginTop: '8px',
        }}
        >
          <Typography variant='body2' color='textSecondary'>
            {position}
          </Typography>
          <Chip label={experience} size='small' />
        </Box>
        <Typography variant='body2' color='textSecondary' style={{ marginTop: '8px' }}>
          Skills:
          {' '}
          {displayedSkills.join(', ')}
          {remainingSkills.length > 0 && (
            <Tooltip title={remainingSkills.join(', ')}>
              <span>
                {' '}
                +
                {remainingSkills.length}
              </span>
            </Tooltip>
          )}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'center' }}>
        <Button variant='outlined' onClick={handleClick}>Book a Slot</Button>
      </CardActions>
    </Card>
  );
}
