import { Octokit } from '@octokit/core';
import { GithubVisbility } from 'utils/constants';
import { Logger } from 'utils/logger';


const checkRepoVisibility = async (repoUrl: string): Promise<string> => {
  try {
    const match = repoUrl.match(/https:\/\/github.com\/(.+)\/(.+)/);
    const octokit = new Octokit({ auth: process.env.GITHUB_ACCESS_TOKEN });
    if (match) {
      const [, owner, repo] = match;
      const resp = await octokit.request('GET /repos/{owner}/{repo}', {
        owner,
        repo,
        headers: {
          'X-GitHub-Api-Version': '2022-11-28',
        },
      });
      if (resp.status === 200) {
        return GithubVisbility.public;
      }
      else {
        return GithubVisbility.private;
      }
    }
    else {
      return GithubVisbility.invalid;
    }
  }
  catch (error) {
    Logger.error('Error checking repository visibility', error);
    return GithubVisbility.private;
  }
};

export default checkRepoVisibility;
