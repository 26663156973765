import { ListItemIcon, MenuItem } from '@mui/material';
import { jsPDF } from 'jspdf';
import { IQuestion } from 'pages/HomePage/queries';
import { Download } from '@mui/icons-material';
import React from 'react';
import { trackEvents } from 'instrumentation/analytics';
import { EEvents } from 'instrumentation/analytics.types';


type TProps = {
  questions: IQuestion[];
}

export default function ExportPDF({ questions }: TProps) {
  const handleExportPdf = () => {
    trackEvents(EEvents.CLICK, {
      feature: 'QuestionDetails - Download',
      type: 'Button',
      label: 'Download',
    });
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
    const lineSpacing = 10;
    const questionSpacing = 20;
    let yOffset = 20;

    doc.setFontSize(18);
    doc.text('Application Questions', margin, yOffset);
    yOffset += 10;

    const addNewPageIfNeeded = (additionalHeight = 0) => {
      if (yOffset + additionalHeight > pageHeight - margin) {
        doc.addPage();
        yOffset = margin;
      }
      return yOffset;
    };

    questions.forEach((question) => {
      yOffset = addNewPageIfNeeded(30); // Check for title and question description space
      doc.setFontSize(16);
      doc.text(`Title: ${question.title}`, margin, yOffset);
      yOffset += 10;

      doc.setFontSize(12);
      const wrappedDescription = doc.splitTextToSize(`Question: ${question.description}`, 180);
      wrappedDescription.forEach((line: string | string[]) => {
        yOffset = addNewPageIfNeeded(5);
        doc.text(line, margin, yOffset);
        yOffset += lineSpacing;
      });

      yOffset += 5;
      doc.setFontSize(10);
      yOffset = addNewPageIfNeeded(5);
      doc.text(`Duration: ${question.duration} mins`, margin, yOffset);
      yOffset += 5;

      const wrappedSkills = doc.splitTextToSize(`Skills: ${question.skills.join(', ')}`, 180);
      wrappedSkills.forEach((line: string | string[]) => {
        yOffset = addNewPageIfNeeded(lineSpacing);
        doc.text(line, margin, yOffset);
        yOffset += lineSpacing;
      });

      yOffset += questionSpacing;
    });

    doc.save('application-questions.pdf');
  };


  return (
    <MenuItem onClick={handleExportPdf}>
      <ListItemIcon>
        <Download fontSize='small' />
      </ListItemIcon>
      Download
    </MenuItem>
  );
}
